import * as deploymentActions from './_deploymentActions';

const deploymentReducer = (state = deploymentActions.initialState, action) => {
    switch (action.type) {
        case deploymentActions.FETCH_DEPLOYMENTS:
        case deploymentActions.FETCH_DEPLOYMENTS_SUCCESS:
        case deploymentActions.FETCH_DEPLOYMENTS_FAILURE:
        case deploymentActions.FETCH_CURRENT_DEPLOYMENT:
        case deploymentActions.FETCH_CURRENT_DEPLOYMENT_SUCCESS:
        case deploymentActions.FETCH_CURRENT_DEPLOYMENT_FAILURE:
        case deploymentActions.CREATE_DEPLOYMENT:
        case deploymentActions.CREATE_DEPLOYMENT_SUCCESS:
        case deploymentActions.CREATE_DEPLOYMENT_FAILURE:
        case deploymentActions.UPDATE_DEPLOYMENT:
        case deploymentActions.UPDATE_DEPLOYMENT_SUCCESS:
        case deploymentActions.UPDATE_DEPLOYMENT_FAILURE:
        case deploymentActions.DELETE_DEPLOYMENT:
        case deploymentActions.DELETE_DEPLOYMENT_SUCCESS:
        case deploymentActions.DELETE_DEPLOYMENT_FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export default deploymentReducer;