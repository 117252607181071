import * as firmwareActions from './_firmwareActions';

const firmwareReducer = (state = firmwareActions.initialState, action) => {
    switch (action.type) {
        case firmwareActions.FETCH_FIRMWARES:
        case firmwareActions.FETCH_FIRMWARES_SUCCESS:
        case firmwareActions.FETCH_FIRMWARES_FAILURE:
        case firmwareActions.FETCH_CURRENT_FIRMWARE:
        case firmwareActions.FETCH_CURRENT_FIRMWARE_SUCCESS:
        case firmwareActions.FETCH_CURRENT_FIRMWARE_FAILURE:
        case firmwareActions.CREATE_FIRMWARE:
        case firmwareActions.CREATE_FIRMWARE_SUCCESS:
        case firmwareActions.CREATE_FIRMWARE_FAILURE:
        case firmwareActions.UPDATE_FIRMWARE:
        case firmwareActions.UPDATE_FIRMWARE_SUCCESS:
        case firmwareActions.UPDATE_FIRMWARE_FAILURE:
        case firmwareActions.DELETE_FIRMWARE:
        case firmwareActions.DELETE_FIRMWARE_SUCCESS:
        case firmwareActions.DELETE_FIRMWARE_FAILURE:
        case firmwareActions.COUNT_FIRMWARES:
        case firmwareActions.COUNT_FIRMWARES_SUCCESS:
        case firmwareActions.COUNT_FIRMWARES_FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export default firmwareReducer;