import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import * as actions from './_deploymentActions';
import deploymentAPI from '../../api/deployment';
import * as firmwareActions from '../firmware/_firmwareActions';
import { history } from '../../store';

const deploymentMiddleware = store => next => (action) => {
    next(action);
    switch (action.type) {
        case actions.FETCH_DEPLOYMENTS_REFRESH:
        case actions.FETCH_DEPLOYMENTS: {
            const filter = get(action, ['payload', 'deployments', 'filter'], {}); 
            deploymentAPI.getDeployments(filter)
                .then((res) => {
                    store.dispatch(actions.fetchDeploymentsSuccess(res, filter));
                })
                .catch(err => store.dispatch(actions.fetchDeploymentsFailure(err, filter)));
            break;
        }
        case actions.FETCH_CURRENT_DEPLOYMENT: {
            const id = get(action, ['payload', 'currentDeployment', 'id'], '');
            deploymentAPI.getDeployment(id)
                .then((res) => {
                    store.dispatch(actions.fetchCurrentDeploymentSuccess(id, res));
                })
                .catch(err => store.dispatch(actions.fetchCurrentDeploymentFailure(id, err)));
            break;
        }
        case actions.CREATE_DEPLOYMENT: {
            const data = get(action, ['payload', 'deployment', 'data'], {});
            deploymentAPI.createDeployment(data)
                .then((res) => {
                    store.dispatch(actions.createDeploymentSuccess(res));
                })
                .catch(err => store.dispatch(actions.createDeploymentFailure(err)));
            break;
        }
        case actions.CREATE_DEPLOYMENT_SUCCESS: {
            store.dispatch(actions.fetchDeploymentsRefresh());
            history.goBack()
            toastr.success('Create succeeded', 'Deployment has been created.');
            break;
        }
        case actions.UPDATE_DEPLOYMENT: {
            const id = get(action, ['payload', 'deployment', 'id'], '');
            const data = get(action, ['payload', 'deployment', 'data'], {});
            deploymentAPI.updateDeployment(id, data)
                .then((res) => {
                    store.dispatch(actions.updateDeploymentSuccess(res));
                    store.dispatch(firmwareActions.fetchCurrentFirmware(res.firmwareId))
                })
                .catch(err => {
                    store.dispatch(actions.updateDeploymentFailure(err))
                    toastr.error('Update failed', err.message);
                });
            break;
        }
        case actions.UPDATE_DEPLOYMENT_SUCCESS: {
            toastr.success('Update succeeded', 'Deployment has been updated.');
            break;
        }
        case actions.DELETE_DEPLOYMENT: {
            const id = get(action, ['payload', 'deployment', 'id'], {});
            const firmwareId = get(action, ['payload', 'deployment', 'firmwareId'], {});
            deploymentAPI.deteteDeployment(id)
                .then((res) => {
                    store.dispatch(actions.deleteDeploymentSuccess(res, firmwareId));
                })
                .catch(err => {
                    store.dispatch(actions.deleteDeploymentFailure(err))
                    toastr.error('Delete failed', err.message);
                });
            break;
        }
        case actions.DELETE_DEPLOYMENT_SUCCESS: {
            const firmwareId = get(action, ['payload', 'deployment', 'firmwareId'], {});
            toastr.success('Delete succeeded', 'Deployment has been deleted.');
            store.dispatch(firmwareActions.fetchCurrentFirmware(firmwareId))
            break;
        }
        default:
            break;
    }
};
export default deploymentMiddleware;