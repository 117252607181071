import API, { APIError } from './api';

const DeviceAPI = {
    getDevices(filter) {
        return new Promise((resolve, reject) => {
            API.axios().get('/devices', filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    getDevice(id, filter) {
        return new Promise((resolve, reject) => {
            API.axios().get(`/devices/${id}`, filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    createDevice(data, filter) {
        return new Promise((resolve, reject) => {
            API.axios().post('/devices', data, filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    updateDevice(id, data) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/devices/${id}`, data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    updateDeviceValues(id, data, filter) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/devices/${id}/set-value`, data, filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    deteteDevice(id, filter) {
        return new Promise((resolve, reject) => {
            API.axios().delete(`/devices/${id}`, filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    triggerFota(id, filter) {
        return new Promise((resolve, reject) => {
            API.axios().post(`/devices/${id}/trigger-fota`, filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    countDevices(filter) {
        return new Promise((resolve, reject) => {
            API.axios().get('/devices/count', filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    updateDeviceDeployment(id, data, filter) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/devices/${id}/update-deployment`, data, filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    archiveDevice(id, data, filter) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/devices/${id}/archive`, data, filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    }
};

export default DeviceAPI;