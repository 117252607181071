import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { get } from 'lodash';
import config from './config/config';

import appReducer from './layouts/_appContainerReducer';
import loginReducer from './views/auth/_loginReducer';
import deviceReducer from './views/device/_deviceReducer';
import userReducer from './views/user/_userReducer';
import serverReducer from './views/server/_serverReducer';
import firmwareReducer from './views/firmware/_firmwareReducer';
import versionReducer from './views/version/_versionReducer';
import deploymentReducer from './views/deployment/_deploymentReducer';
import hardwareReducer from './views/hardware/_hardwareReducer';
import activityReducer from './views/activity/_activityReducer';
import deviceStatsReducer from './views/deviceStats/_deviceStatsReducer';


const combiledReducers = combineReducers({
    app: appReducer,
    toastr: toastrReducer,
    auth: loginReducer,
    devices: deviceReducer,
    users: userReducer,
    servers: serverReducer,
    firmwares: firmwareReducer,
    versions: versionReducer,
    deployments: deploymentReducer,
    hardwares: hardwareReducer,
    activities: activityReducer,
    deviceStats: deviceStatsReducer
});

const rootReducer = (state, action) => {
    // Log actions for debugging
    if (config.env === 'development') {
        console.log(`${action.type} `, action);
    }

    if (action.type === REHYDRATE) {
        return {
            ...state,
            ...action.payload,
            app: {
                ...get(action, ['payload', 'app'], {}),
            },
            auth: {
                ...get(action, ['payload', 'auth'], {}),
                user: get(action, ['payload', 'auth', 'user'], {}),
                userSignUp: get(state, ['auth', 'userSignUp'], {}),
                userLogIn: get(state, ['auth', 'userLogIn'], {}),
            }
        };
    }

    return combiledReducers(state, action);
};
export default rootReducer;