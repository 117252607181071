import { get } from 'lodash';
import { createAction } from '../../utils/actionHelper';

export const initialState = {
    activities: {
        data: null,
        isFetching: false,
        filter: null
    },
    currentActivity: {
        data: null,
        isFetching: false,
        filter: null
    },
    countActivities: {
        count: null,
        isFetching: false,
        filter: null
    }
};

// fetch activities
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const fetchActivities = createAction(FETCH_ACTIVITIES, (filter) => ({
    activities: {
        isFetching: true,
        data: [],
        filter
    }
}));
export const FETCH_ACTIVITIES_REFRESH = 'FETCH_ACTIVITIES_REFRESH';
export const fetchActivitiesRefresh = createAction(FETCH_ACTIVITIES_REFRESH, (filter) => ({
    activities: {
        isFetching: false,
        filter
    }
}));
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const fetchActivitiesSuccess = createAction(FETCH_ACTIVITIES_SUCCESS, (data, filter) => ({
    activities: {
        isFetching: false,
        data,
        filter
    }
}));
export const FETCH_ACTIVITIES_FAILURE = 'FETCH_ACTIVITIES_FAILURE';
export const fetchActivitiesFailure = createAction(FETCH_ACTIVITIES_FAILURE, (err, filter) => ({
    activities: {
        err,
        isFetching: false,
        filter
    }
}));
// fetch total activities
export const COUNT_ACTIVITIES = 'COUNT_ACTIVITIES';
export const countActivities = createAction(COUNT_ACTIVITIES, (filter) => ({
    countActivities: {
        isFetching: true,
        count: {},
        filter
    }
}));
export const COUNT_ACTIVITIES_SUCCESS = 'COUNT_ACTIVITIES_SUCCESS';
export const countActivitiesSuccess = createAction(COUNT_ACTIVITIES_SUCCESS, (count, filter) => ({
    countActivities: {
        isFetching: false,
        count,
        filter
    }
}));
export const COUNT_ACTIVITIES_FAILURE = 'COUNT_ACTIVITIES_FAILURE';
export const countActivitiesFailure = createAction(COUNT_ACTIVITIES_FAILURE, (err, filter) => ({
    countActivities: {
        filter,
        err,
        isFetching: false
    }
}));
// fetch activity by id
export const FETCH_CURRENT_ACTIVITY = 'FETCH_CURRENT_ACTIVITY';
export const fetchCurrentActivity = createAction(FETCH_CURRENT_ACTIVITY, (id, filter) => ({
    currentActivity: {
        id,
        isFetching: true,
        data: [],
        filter
    }
}));
export const FETCH_CURRENT_ACTIVITY_SUCCESS = 'FETCH_CURRENT_ACTIVITY_SUCCESS';
export const fetchCurrentActivitySuccess = createAction(FETCH_CURRENT_ACTIVITY_SUCCESS, (id, data, filter) => ({
    currentActivity: {
        id,
        isFetching: false,
        data,
        filter
    }
}));
export const FETCH_CURRENT_ACTIVITY_FAILURE = 'FETCH_CURRENT_ACTIVITY_FAILURE';
export const fetchCurrentActivityFailure = createAction(FETCH_CURRENT_ACTIVITY_FAILURE, (id, err, filter) => ({
    currentActivity: {
        id,
        err,
        isFetching: false,
        filter
    }
}));

export const getActivitiesData = state => get(state, ['activities', 'activities', 'data'], []);
export const getCountActivities = state => get(state, ['activities', 'countActivities', 'count'], {});
export const getActivitiesIsFetching = state => get(state, ['activities', 'activities', 'isFetching'], false);
export const getCurrentActivityData = state => get(state, ['activities', 'currentActivity', 'data'], {});
export const getCurrentActivityIsFetching = state => get(state, ['activities', 'currentActivity', 'isFetching'], false);