import * as deviceStatsActions from './_deviceStatsActions';

const deviceStatsReducer = (state = deviceStatsActions.initialState, action) => {
    switch (action.type) {
        case deviceStatsActions.FETCH_DEVICE_STATS:
        case deviceStatsActions.FETCH_DEVICE_STATS_SUCCESS:
        case deviceStatsActions.FETCH_DEVICE_STATS_FAILURE:
        case deviceStatsActions.COUNT_DEVICE_STATS:
        case deviceStatsActions.COUNT_DEVICE_STATS_SUCCESS:
        case deviceStatsActions.COUNT_DEVICE_STATS_FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export default deviceStatsReducer;
