import { get } from 'lodash';
import * as actions from './_deviceStatsActions';
import deviceStatsAPI from '../../api/deviceStats';

const deviceStatsMiddleware = store => next => (action) => {
    next(action);
    switch (action.type) {
        case actions.FETCH_DEVICE_STATS_REFRESH:
        case actions.FETCH_DEVICE_STATS: {
            const filter = get(action, ['payload', 'deviceStats', 'filter'], '');
            deviceStatsAPI.getDeviceStats(filter)
                .then((res) => {
                    store.dispatch(actions.fetchDeviceStatsSuccess(filter, res));
                })
                .catch(err => store.dispatch(actions.fetchDeviceStatsFailure(filter, err)));
            break;
        }
        case actions.COUNT_DEVICE_STATS: {
            const filter = get(action, ['payload', 'countDeviceStats', 'filter'], {});
            const newFilter = { params: { where: filter.params.where || filter.params.filter.where } };
            deviceStatsAPI.countDeviceStats(newFilter)
                .then((res) => {
                    store.dispatch(actions.countDeviceStatsSuccess(res, newFilter));
                })
                .catch(err => store.dispatch(actions.countDeviceStatsFailure(newFilter, err)));
            break;
        }
        default:
            break;
    }
};
export default deviceStatsMiddleware;