import { get } from 'lodash';
import { createAction } from '../../utils/actionHelper';

export const initialState = {
    deviceStats: {
        data: null,
        isFetching: false,
        filter: null
    },
    countDeviceStats: {
        count: null,
        isFetching: false,
        filter: null
    }
};

// fetch device stats
export const FETCH_DEVICE_STATS = 'FETCH_DEVICE_STATS';
export const fetchDeviceStats = createAction(FETCH_DEVICE_STATS, (filter) => ({
    deviceStats: {
        isFetching: true,
        data: [],
        filter
    }
}));
export const FETCH_DEVICE_STATS_REFRESH = 'FETCH_DEVICE_STATS_REFRESH';
export const fetchDeviceStatsRefresh = createAction(FETCH_DEVICE_STATS_REFRESH, (filter) => ({
    deviceStats: {
        isFetching: false,
        filter
    }
}));
export const FETCH_DEVICE_STATS_SUCCESS = 'FETCH_DEVICE_STATS_SUCCESS';
export const fetchDeviceStatsSuccess = createAction(FETCH_DEVICE_STATS_SUCCESS, (filter, data) => ({
    deviceStats: {
        isFetching: false,
        data,
        filter
    }
}));
export const FETCH_DEVICE_STATS_FAILURE = 'FETCH_DEVICE_STATS_FAILURE';
export const fetchDeviceStatsFailure = createAction(FETCH_DEVICE_STATS_FAILURE, (filter, err) => ({
    deviceStats: {
        err,
        isFetching: false,
        filter
    }
}));
// fetch total device stats
export const COUNT_DEVICE_STATS = 'COUNT_DEVICE_STATS';
export const countDeviceStats = createAction(COUNT_DEVICE_STATS, (filter) => ({
    countDeviceStats: {
        isFetching: true,
        count: {},
        filter
    }
}));
export const COUNT_DEVICE_STATS_SUCCESS = 'COUNT_DEVICE_STATS_SUCCESS';
export const countDeviceStatsSuccess = createAction(COUNT_DEVICE_STATS_SUCCESS, (count, filter) => ({
    countDeviceStats: {
        isFetching: false,
        count,
        filter
    }
}));
export const COUNT_DEVICE_STATS_FAILURE = 'COUNT_DEVICE_STATS_FAILURE';
export const countDeviceStatsFailure = createAction(COUNT_DEVICE_STATS_FAILURE, (filter, err) => ({
    countDeviceStats: {
        filter,
        err,
        isFetching: false
    }
}));

export const getDeviceStatsData = state => get(state, ['deviceStats', 'deviceStats', 'data'], []);
export const getCountDeviceStats = state => get(state, ['deviceStats', 'countDeviceStats', 'count'], {});
export const getDeviceStatsIsFetching = state => get(state, ['deviceStats', 'deviceStats', 'isFetching'], false);