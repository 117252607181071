import API, { APIError } from './api';

const Hardware = {
    addHardware(data) {
        return new Promise((resolve, reject) => {
            API.axios().post('/hardwares', data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    getHardwares() {
        return new Promise((resolve, reject) => {
            API.axios().get('/hardwares')
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    getHardware(id) {
        return new Promise((resolve, reject) => {
            API.axios().get(`/hardwares/${id}`)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    updateHardware(id, data) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/hardwares/${id}`, data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    deteteHardware(id) {
        return new Promise((resolve, reject) => {
            API.axios().delete(`/hardwares/${id}`)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    countHardwares() {
        return new Promise((resolve, reject) => {
            API.axios().get('/hardwares/count')
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    }
};

export default Hardware;