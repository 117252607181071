import * as loginActions from './_loginActions';

const initialState = {
    redirectTo: '',
    user: {
        data: null,
        isFetching: false,
        err: null,
        isLogin: false
    },
    userSignUp: {
        data: {},
        isFetching: false,
        err: null
    },
    userLogIn: {
        data: {},
        isFetching: false,
        err: null
    }
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case loginActions.MUST_LOGIN:
            return {
                redirectTo: action.payload.redirectTo === '/login' ? '/' : action.payload.redirectTo,
                user: {
                    isFetching: action.payload.user.isFetching,
                    isLogin: action.payload.user.isLogin
                }
            };

        case loginActions.GET_IS_SYSADMIN_SUCCESS:
        case loginActions.SUBMIT_LOGIN:
        case loginActions.SUBMIT_LOGIN_SUCCESS:
        case loginActions.SUBMIT_LOGIN_FAILURE:
        case loginActions.SUBMIT_SIGNUP:
        case loginActions.SUBMIT_SIGNUP_SUCCESS:
        case loginActions.SUBMIT_SIGNUP_FAILURE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }

};
export default loginReducer;