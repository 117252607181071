import { get } from 'lodash';
import { createAction } from '../../utils/actionHelper';

export const initialState = {
    firmwares: {
        data: null,
        isFetching: false
    },
    firmware: {
        data: null,
        isFetching: false,
        done: false
    },
    currentFirmware: {
        data: null,
        isFetching: false
    },
    countFirmwares: {
        count: null,
        isFetching: false
    }
};

// fetch firmwares
export const FETCH_FIRMWARES = 'FETCH_FIRMWARES';
export const fetchFirmwares = createAction(FETCH_FIRMWARES, () => ({
    firmwares: {
        isFetching: true,
        data: []
    }
}));
export const FETCH_FIRMWARES_REFRESH = 'FETCH_FIRMWARES_REFRESH';
export const fetchFirmwaresRefresh = createAction(FETCH_FIRMWARES_REFRESH, () => ({
    firmwares: {
        isFetching: false
    }
}));
export const FETCH_FIRMWARES_SUCCESS = 'FETCH_FIRMWARES_SUCCESS';
export const fetchFirmwaresSuccess = createAction(FETCH_FIRMWARES_SUCCESS, (data) => ({
    firmwares: {
        isFetching: false,
        data
    }
}));
export const FETCH_FIRMWARES_FAILURE = 'FETCH_FIRMWARES_FAILURE';
export const fetchFirmwaresFailure = createAction(FETCH_FIRMWARES_FAILURE, (err) => ({
    firmwares: {
        err,
        isFetching: false
    }
}));

// fetch firmware by id
export const FETCH_CURRENT_FIRMWARE = 'FETCH_CURRENT_FIRMWARE';
export const fetchCurrentFirmware = createAction(FETCH_CURRENT_FIRMWARE, (id) => ({
    currentFirmware: {
        id,
        isFetching: true,
        data: []
    }
}));
export const FETCH_CURRENT_FIRMWARE_SUCCESS = 'FETCH_CURRENT_FIRMWARE_SUCCESS';
export const fetchCurrentFirmwareSuccess = createAction(FETCH_CURRENT_FIRMWARE_SUCCESS, (id, data) => ({
    currentFirmware: {
        id,
        isFetching: false,
        data
    }
}));
export const FETCH_CURRENT_FIRMWARE_FAILURE = 'FETCH_CURRENT_FIRMWARE_FAILURE';
export const fetchCurrentFirmwareFailure = createAction(FETCH_CURRENT_FIRMWARE_FAILURE, (id, err) => ({
    currentFirmware: {
        id,
        err,
        isFetching: false
    }
}));

// create firmware
export const CREATE_FIRMWARE = 'CREATE_FIRMWARE';
export const createFirmware = createAction(CREATE_FIRMWARE, (data) => ({
    firmware: {
        isFetching: true,
        data,
        done: false
    }
}));
export const CREATE_FIRMWARE_SUCCESS = 'CREATE_FIRMWARE_SUCCESS';
export const createFirmwareSuccess = createAction(CREATE_FIRMWARE_SUCCESS, (data) => ({
    firmware: {
        isFetching: false,
        data,
        done: true
    }
}));
export const CREATE_FIRMWARE_FAILURE = 'CREATE_FIRMWARE_FAILURE';
export const createFirmwareFailure = createAction(CREATE_FIRMWARE_FAILURE, (err) => ({
    firmware: {
        err,
        isFetching: false
    }
}));

// update firmware
export const UPDATE_FIRMWARE = 'UPDATE_FIRMWARE';
export const updateFirmware = createAction(UPDATE_FIRMWARE, (id, data) => ({
    firmware: {
        isFetching: true,
        id,
        data
    }
}));
export const UPDATE_FIRMWARE_SUCCESS = 'UPDATE_FIRMWARE_SUCCESS';
export const updateFirmwareSuccess = createAction(UPDATE_FIRMWARE_SUCCESS, (data) => ({
    firmware: {
        isFetching: false,
        data
    }
}));
export const UPDATE_FIRMWARE_FAILURE = 'UPDATE_FIRMWARE_FAILURE';
export const updateFirmwareFailure = createAction(UPDATE_FIRMWARE_FAILURE, (err) => ({
    firmware: {
        err,
        isFetching: false
    }
}));

// delete firmware
export const DELETE_FIRMWARE = 'DELETE_FIRMWARE';
export const deleteFirmware = createAction(DELETE_FIRMWARE, (id) => ({
    firmware: {
        id,
        isFetching: true
    }
}));
export const DELETE_FIRMWARE_SUCCESS = 'DELETE_FIRMWARE_SUCCESS';
export const deleteFirmwareSuccess = createAction(DELETE_FIRMWARE_SUCCESS, (data) => ({
    firmware: {
        isFetching: false,
        data
    }
}));
export const DELETE_FIRMWARE_FAILURE = 'DELETE_FIRMWARE_FAILURE';
export const deleteFirmwareFailure = createAction(DELETE_FIRMWARE_FAILURE, (err) => ({
    firmware: {
        err,
        isFetching: false
    }
}));

// total firmwares
export const COUNT_FIRMWARES = 'COUNT_FIRMWARES';
export const countFirmwares = createAction(COUNT_FIRMWARES, () => ({
    countFirmwares: {
        isFetching: true,
        count: {}
    }
}));
export const COUNT_FIRMWARES_SUCCESS = 'COUNT_FIRMWARES_SUCCESS';
export const countFirmwaresSuccess = createAction(COUNT_FIRMWARES_SUCCESS, (count) => ({
    countFirmwares: {
        isFetching: false,
        count
    }
}));
export const COUNT_FIRMWARES_FAILURE = 'COUNT_FIRMWARES_FAILURE';
export const countFirmwaresFailure = createAction(COUNT_FIRMWARES_FAILURE, (err) => ({
    countFirmwares: {
        err,
        isFetching: false
    }
}));

export const getFirmwaresData = state => get(state, ['firmwares', 'firmwares', 'data'], []);
export const getFirmwaresIsFetching = state => get(state, ['firmwares', 'firmwares', 'isFetching'], false);
export const getUpdateSFirmwareIsFetching = state => get(state, ['firmwares', 'firmware', 'isFetching'], false);
export const getUpdateFirmwareErrorMessage = state => get(state, ['firmwares', 'firmware', 'err', 'message'], '');
export const getCreateFirmwareDone = state => get(state, ['firmwares', 'firmware', 'done'], false);
export const getCreateFirmwareErrorMessage = state => get(state, ['firmwares', 'firmware', 'err', 'message'], '');
export const getCreateFirmwareDoneData = state => get(state, ['firmwares', 'firmware', 'data'], {});
export const getCurrentFirmwareData = state => get(state, ['firmwares', 'currentFirmware', 'data'], {});
export const getCurrentFirmwareIsFetching = state => get(state, ['firmwares', 'currentFirmware', 'isFetching'], false);
export const getCountFirmwares = state => get(state, ['firmwares', 'countFirmwares', 'count'], {});
