import { get } from 'lodash';
import * as actions from './_versionActions';
import { toastr } from 'react-redux-toastr';
import versionAPI from '../../api/version';
import { history } from '../../store';

const versionMiddleware = store => next => (action) => {
    next(action);
    switch (action.type) {
        case actions.CREATE_VERSION: {
            const data = get(action, ['payload', 'version', 'data'], {});
            const firmwareId = get(action, ['payload', 'version', 'firmwareId'], '');
            versionAPI.createVersion(firmwareId, data)
                .then((res) => {
                    store.dispatch(actions.createVersionSuccess(firmwareId, res));
                })
                .catch(err => store.dispatch(actions.createVersionFailure(firmwareId, err)));
            break;
        }
        case actions.CREATE_VERSION_SUCCESS: {
            history.goBack()
            toastr.success('Create succeeded', 'Version has been created.');
            break;
        }
        default:
            break;
    }
};
export default versionMiddleware;