import React from 'react';

const Spinner = ({ size, color, spacing }) => <div
    className={`spinner-border text-${color || 'primary'} ${spacing || 'm-1'} spinner-border-${size}`}
    role="status" />;

const Grow = ({ size, color, spacing }) => <div
    className={`spinner-grow text-${color || 'primary'} ${spacing || 'm-1'} spinner-grow-${size}`}
    role="status" />;

const Loading = ({ isLoading = false, type = 'grow', size = 'sm', color = 'primary', spacing = 'm-1' }) => {
    if (!isLoading) {
        return null;
    }
    switch (type) {
        case 'grow':
            return <Grow size={size} color={color} spacing={spacing} />;
        case 'spinner':
        default:
            return <Spinner size={size} color={color} spacing={spacing} />;
    }
};
export default Loading;