import API, { APIError } from './api';

const ActivityAPI = {
    getActivities(filter) {
        return new Promise((resolve, reject) => {
            API.axios().get('/activities', filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    getActivity(id, filter) {
        return new Promise((resolve, reject) => {
            API.axios().get(`/activities/${id}`, filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    countActivities(filter) {
        return new Promise((resolve, reject) => {
            API.axios().get('/activities/count', filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    }
};

export default ActivityAPI;