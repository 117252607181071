import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import * as actions from './_deviceActions';
import deviceAPI from '../../api/device';
import { history } from '../../store';

const deviceMiddleware = store => next => (action) => {
    next(action);
    switch (action.type) {
        case actions.FETCH_DEVICES_REFRESH:
        case actions.FETCH_DEVICES: {
            const filter = get(action, ['payload', 'devices', 'filter'], '');
            deviceAPI.getDevices(filter)
                .then((res) => {
                    store.dispatch(actions.fetchDevicesSuccess(filter, res));
                })
                .catch(err => store.dispatch(actions.fetchDevicesFailure(filter, err)));
            break;
        }
        case actions.COUNT_DEVICES: {
            const filter = get(action, ['payload', 'countDevices', 'filter'], {});
            const newFilter = { params: { where: filter.params.where || filter.params.filter.where } };
            deviceAPI.countDevices(newFilter)
                .then((res) => {
                    store.dispatch(actions.countDevicesSuccess(res, newFilter));
                })
                .catch(err => store.dispatch(actions.countDevicesFailure(newFilter, err)));
            break;
        }
        case actions.FETCH_CURRENT_DEVICE: {
            const id = get(action, ['payload', 'currentDevice', 'id'], '');
            const filter = get(action, ['payload', 'currentDevice', 'filter'], {});
            deviceAPI.getDevice(id, filter)
                .then((res) => {
                    store.dispatch(actions.fetchCurrentDeviceSuccess(id, res, filter));
                })
                .catch(err => store.dispatch(actions.fetchCurrentDeviceFailure(id, err, filter)));
            break;
        }
        case actions.CREATE_DEVICE: {
            const data = get(action, ['payload', 'device', 'data'], {});
            const filter = get(action, ['payload', 'device', 'filter'], {});
            deviceAPI.createDevice(data, filter)
                .then((res) => {
                    store.dispatch(actions.createDeviceSuccess(res, filter));
                })
                .catch(err => store.dispatch(actions.createDeviceFailure(err, filter)));
            break;
        }
        case actions.CREATE_DEVICE_SUCCESS: {
            const filter = get(action, ['payload', 'device', 'filter'], {});
            store.dispatch(actions.fetchDevicesRefresh(filter.all !== 'all' ? filter : {
                params: filter.params
            }));
            store.dispatch(actions.countDevices(filter.all !== 'all' ? filter : {
                params: {
                    where: filter.params.filter.where ? filter.params.filter.where : {}
                }
            }));
            toastr.success('Add succeeded', 'Device has been added.');
            history.push('/devices')
            break;
        }
        case actions.UPDATE_DEVICE: {
            const id = get(action, ['payload', 'device', 'id'], '');
            const data = get(action, ['payload', 'device', 'data'], {});
            deviceAPI.updateDevice(id, data)
                .then((res) => {
                    store.dispatch(actions.updateDeviceSuccess(res));
                })
                .catch(err => store.dispatch(actions.updateDeviceFailure(err)));
            break;
        }
        case actions.UPDATE_DEVICE_SUCCESS: {
            store.dispatch(actions.fetchDevicesRefresh());
            break;
        }
        case actions.UPDATE_DEVICE_VALUES: {
            const id = get(action, ['payload', 'device', 'id'], '');
            const data = get(action, ['payload', 'device', 'data'], {});
            const filter = get(action, ['payload', 'device', 'filter'], {});
            deviceAPI.updateDeviceValues(id, data, filter)
                .then((res) => {
                    store.dispatch(actions.updateDeviceValuesSuccess(res, filter));
                })
                .catch(err => store.dispatch(actions.updateDeviceValuesFailure(err)));
            break;
        }
        case actions.UPDATE_DEVICE_VALUES_SUCCESS: {
            const filter = get(action, ['payload', 'device', 'filter'], {});
            store.dispatch(actions.fetchDevicesRefresh(filter.all !== 'all' ? filter : {
                params: filter.params
            }));
            toastr.success('Update succeeded', 'FirmwareUpdate value has been updated.');
            break;
        }
        case actions.UPDATE_DEVICE_DEPLOYMENT: {
            const id = get(action, ['payload', 'device', 'id'], '');
            const data = get(action, ['payload', 'device', 'data'], {});
            const filter = get(action, ['payload', 'device', 'filter'], {});
            deviceAPI.updateDeviceDeployment(id, data, filter)
                .then((res) => {
                    store.dispatch(actions.updateDeviceDeploymentSuccess(res, filter));
                })
                .catch(err => {
                    store.dispatch(actions.updateDeviceDeploymentFailure(err))
                    toastr.error('Update failed', err.message);
                });
            break;
        }
        case actions.UPDATE_DEVICE_DEPLOYMENT_SUCCESS: {
            const filter = get(action, ['payload', 'device', 'filter'], {});
            store.dispatch(actions.fetchDevicesRefresh(filter.all !== 'all' ? filter : {
                params: filter.params
            }));
            toastr.success('Update succeeded', 'Deployment has been updated.');
            break;
        }
        case actions.ARCHIVE_DEVICE: {
            const id = get(action, ['payload', 'device', 'id'], '');
            const data = get(action, ['payload', 'device', 'data'], {});
            const filter = get(action, ['payload', 'device', 'filter'], {});
            deviceAPI.archiveDevice(id, data, filter)
                .then((res) => {
                    store.dispatch(actions.archiveDeviceSuccess(res, filter));
                    toastr.success('Update succeeded', `Device has been ${res.archive ? 'archived' : 'unarchived'}.`);
                })
                .catch(err => {
                    store.dispatch(actions.archiveDeviceFailure(err))
                    toastr.error('Update failed', err.message);
                });
            break;
        }
        case actions.ARCHIVE_DEVICE_SUCCESS: {
            const filter = get(action, ['payload', 'device', 'filter'], {});
            store.dispatch(actions.fetchDevicesRefresh(filter.all !== 'all' ? filter : {
                params: filter.params
            }));
            break;
        }
        case actions.DELETE_DEVICE: {
            const id = get(action, ['payload', 'device', 'id'], {});
            const filter = get(action, ['payload', 'device', 'filter'], {});
            deviceAPI.deteteDevice(id, filter)
                .then((res) => {
                    store.dispatch(actions.deleteDeviceSuccess(res, filter));
                })
                .catch(err => store.dispatch(actions.deleteDeviceFailure(err, filter)));
            break;
        }
        case actions.DELETE_DEVICE_SUCCESS: {
            const filter = get(action, ['payload', 'device', 'filter'], {});
            store.dispatch(actions.fetchDevicesRefresh(filter.all !== 'all' ? filter : {
                params: filter.params
            }));
            store.dispatch(actions.countDevices(filter.all !== 'all' ? filter : {
                params: {
                    where: filter.params.filter.where ? filter.params.filter.where : {}
                }
            }));
            toastr.success('Delete succeeded', 'Device has been deleted.');
            break;
        }
        case actions.DELETE_DEVICE_FAILURE: {
            toastr.error('Delete failed', 'Cannot delete this device.');
            break;
        }
        case actions.TRIGGER_FOTA: {
            const id = get(action, ['payload', 'device', 'id'], {});
            const filter = get(action, ['payload', 'device', 'filter'], {});
            deviceAPI.triggerFota(id, filter)
                .then((res) => {
                    store.dispatch(actions.triggerFotaSuccess(res, filter));
                })
                .catch(err => {
                    store.dispatch(actions.triggerFotaFailure(err, filter));
                    toastr.error('Trigger FOTA failed', err.message);
                });
            break;
        }
        case actions.TRIGGER_FOTA_SUCCESS: {
            const filter = get(action, ['payload', 'device', 'filter'], {});
            store.dispatch(actions.fetchDevicesRefresh(filter.all !== 'all' ? filter : {
                params: filter.params
            }));
            toastr.success('Trigger FOTA succeeded', 'FOTA has been Triggered.');
            break;
        }
        default:
            break;
    }
};
export default deviceMiddleware;