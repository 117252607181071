import { get } from 'lodash';
import { createAction } from '../../utils/actionHelper';

export const initialState = {
    hardwares: {
        data: null,
        isFetching: false
    },
    hardware: {
        data: null,
        isFetching: false,
        done: false
    },
    currentHardware: {
        data: null,
        isFetching: false
    },
    countHardwares: {
        count: null,
        isFetching: false
    }
};

// fetch hardwares
export const FETCH_HARDWARES = 'FETCH_HARDWARES';
export const fetchHardwares = createAction(FETCH_HARDWARES, () => ({
    hardwares: {
        isFetching: true,
        data: []
    }
}));
export const FETCH_HARDWARES_REFRESH = 'FETCH_HARDWARES_REFRESH';
export const fetchHardwaresRefresh = createAction(FETCH_HARDWARES_REFRESH, () => ({
    hardwares: {
        isFetching: false
    }
}));
export const FETCH_HARDWARES_SUCCESS = 'FETCH_HARDWARES_SUCCESS';
export const fetchHardwaresSuccess = createAction(FETCH_HARDWARES_SUCCESS, (data) => ({
    hardwares: {
        isFetching: false,
        data
    }
}));
export const FETCH_HARDWARES_FAILURE = 'FETCH_HARDWARES_FAILURE';
export const fetchHardwaresFailure = createAction(FETCH_HARDWARES_FAILURE, (err) => ({
    hardwares: {
        err,
        isFetching: false
    }
}));

// fetch hardware by id
export const FETCH_CURRENT_HARDWARE = 'FETCH_CURRENT_HARDWARE';
export const fetchCurrentHardware = createAction(FETCH_CURRENT_HARDWARE, (id) => ({
    currentHardware: {
        id,
        isFetching: true,
        data: []
    }
}));
export const FETCH_CURRENT_HARDWARE_SUCCESS = 'FETCH_CURRENT_HARDWARE_SUCCESS';
export const fetchCurrentHardwareSuccess = createAction(FETCH_CURRENT_HARDWARE_SUCCESS, (id, data) => ({
    currentHardware: {
        id,
        isFetching: false,
        data
    }
}));
export const FETCH_CURRENT_HARDWARE_FAILURE = 'FETCH_CURRENT_HARDWARE_FAILURE';
export const fetchCurrentHardwareFailure = createAction(FETCH_CURRENT_HARDWARE_FAILURE, (id, err) => ({
    currentHardware: {
        id,
        err,
        isFetching: false
    }
}));

// add hardware
export const ADD_HARDWARE = 'ADD_HARDWARE';
export const addHardware = createAction(ADD_HARDWARE, (data) => ({
    hardware: {
        isFetching: true,
        data,
        done: false
    }
}));
export const ADD_HARDWARE_SUCCESS = 'ADD_HARDWARE_SUCCESS';
export const addHardwareSuccess = createAction(ADD_HARDWARE_SUCCESS, (data) => ({
    hardware: {
        isFetching: false,
        data,
        done: true
    }
}));
export const ADD_HARDWARE_FAILURE = 'ADD_HARDWARE_FAILURE';
export const addHardwareFailure = createAction(ADD_HARDWARE_FAILURE, (err) => ({
    hardware: {
        err,
        isFetching: false
    }
}));

// update hardware
export const UPDATE_HARDWARE = 'UPDATE_HARDWARE';
export const updateHardware = createAction(UPDATE_HARDWARE, (id, data) => ({
    hardware: {
        isFetching: true,
        id,
        data,
        done: false
    }
}));
export const UPDATE_HARDWARE_SUCCESS = 'UPDATE_HARDWARE_SUCCESS';
export const updateHardwareSuccess = createAction(UPDATE_HARDWARE_SUCCESS, (data) => ({
    hardware: {
        isFetching: false,
        data,
        done: true
    }
}));
export const UPDATE_HARDWARE_FAILURE = 'UPDATE_HARDWARE_FAILURE';
export const updateHardwareFailure = createAction(UPDATE_HARDWARE_FAILURE, (err) => ({
    hardware: {
        err,
        isFetching: false
    }
}));

// delete hardware
export const DELETE_HARDWARE = 'DELETE_HARDWARE';
export const deleteHardware = createAction(DELETE_HARDWARE, (id) => ({
    hardware: {
        id,
        isFetching: true
    }
}));
export const DELETE_HARDWARE_SUCCESS = 'DELETE_HARDWARE_SUCCESS';
export const deleteHardwareSuccess = createAction(DELETE_HARDWARE_SUCCESS, (data) => ({
    hardware: {
        isFetching: false,
        data
    }
}));
export const DELETE_HARDWARE_FAILURE = 'DELETE_HARDWARE_FAILURE';
export const deleteHardwareFailure = createAction(DELETE_HARDWARE_FAILURE, (err) => ({
    hardware: {
        err,
        isFetching: false
    }
}));

// total hardwares
export const COUNT_HARDWARES = 'COUNT_HARDWARES';
export const countHardwares = createAction(COUNT_HARDWARES, () => ({
    countHardwares: {
        isFetching: true,
        count: {}
    }
}));
export const COUNT_HARDWARES_SUCCESS = 'COUNT_HARDWARES_SUCCESS';
export const countHardwaresSuccess = createAction(COUNT_HARDWARES_SUCCESS, (count) => ({
    countHardwares: {
        isFetching: false,
        count
    }
}));
export const COUNT_HARDWARES_FAILURE = 'COUNT_HARDWARES_FAILURE';
export const countHardwaresFailure = createAction(COUNT_HARDWARES_FAILURE, (err) => ({
    countHardwares: {
        err,
        isFetching: false
    }
}));

export const getHardwaresData = state => get(state, ['hardwares', 'hardwares', 'data'], []);
export const getHardwaresIsFetching = state => get(state, ['hardwares', 'hardwares', 'isFetching'], false);
export const getUpdateHardwareIsFetching = state => get(state, ['hardwares', 'hardware', 'isFetching'], false);
export const getUpdateHardwareErrorMessage = state => get(state, ['hardwares', 'hardware', 'err', 'message'], '');
export const getAddHardwareDone = state => get(state, ['hardwares', 'hardware', 'done'], false);
export const getAddHardwareErrorMessage = state => get(state, ['hardwares', 'hardware', 'err', 'message'], '');
export const getAddHardwareDoneData = state => get(state, ['hardwares', 'hardware', 'data'], {});
export const getCurrentHardwareData = state => get(state, ['hardwares', 'currentHardware', 'data'], {});
export const getUpdateHardwareDone = state => get(state, ['hardwares', 'hardware', 'done'], false);
export const getCountHardwares = state => get(state, ['hardwares', 'countHardwares', 'count'], {});
