import * as activityActions from './_activityActions';

const activityReducer = (state = activityActions.initialState, action) => {
    switch (action.type) {
        case activityActions.FETCH_ACTIVITIES:
        case activityActions.FETCH_ACTIVITIES_SUCCESS:
        case activityActions.FETCH_ACTIVITIES_FAILURE:
        case activityActions.FETCH_CURRENT_ACTIVITY:
        case activityActions.FETCH_CURRENT_ACTIVITY_SUCCESS:
        case activityActions.FETCH_CURRENT_ACTIVITY_FAILURE:
        case activityActions.COUNT_ACTIVITIES:
        case activityActions.COUNT_ACTIVITIES_SUCCESS:
        case activityActions.COUNT_ACTIVITIES_FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export default activityReducer;
