import { get } from 'lodash';
import { createAction } from '../../utils/actionHelper';

export const initialState = {
    versions: {
        data: null,
        isFetching: false
    },
    version: {
        data: null,
        isFetching: false,
        done: false
    }
};

// create Version
export const CREATE_VERSION = 'CREATE_VERSION';
export const createVersion = createAction(CREATE_VERSION, (firmwareId, data) => ({
    version: {
        firmwareId,
        isFetching: true,
        data
    }
}));
export const CREATE_VERSION_SUCCESS = 'CREATE_VERSION_SUCCESS';
export const createVersionSuccess = createAction(CREATE_VERSION_SUCCESS, (firmwareId, data) => ({
    version: {
        firmwareId,
        isFetching: false,
        data,
        done: true
    }
}));
export const CREATE_VERSION_FAILURE = 'CREATE_VERSION_FAILURE';
export const createVersionFailure = createAction(CREATE_VERSION_FAILURE, (firmwareId, err) => ({
    version: {
        firmwareId,
        err,
        isFetching: false
    }
}));

export const getVersionsData = state => get(state, ['versions', 'versions', 'data'], []);
export const getVersionsIsFetching = state => get(state, ['versions', 'versions', 'isFetching'], false);
export const getCreateVersionIsFetching = state => get(state, ['versions', 'version', 'isFetching'], false);
export const getCreateVersionErrorMessage = state => get(state, ['versions', 'version', 'err', 'message'], null);
export const getCreateDone = state => get(state, ['versions', 'version', 'done'], false);
export const getCreatedVersionData = state => get(state, ['versions', 'version', 'data'], false);