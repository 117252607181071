import * as hardwareActions from './_hardwareActions';

const hardwareReducer = (state = hardwareActions.initialState, action) => {
    switch (action.type) {
        case hardwareActions.FETCH_HARDWARES:
        case hardwareActions.FETCH_HARDWARES_SUCCESS:
        case hardwareActions.FETCH_HARDWARES_FAILURE:
        case hardwareActions.FETCH_CURRENT_HARDWARE:
        case hardwareActions.FETCH_CURRENT_HARDWARE_SUCCESS:
        case hardwareActions.FETCH_CURRENT_HARDWARE_FAILURE:
        case hardwareActions.ADD_HARDWARE:
        case hardwareActions.ADD_HARDWARE_SUCCESS:
        case hardwareActions.ADD_HARDWARE_FAILURE:
        case hardwareActions.UPDATE_HARDWARE:
        case hardwareActions.UPDATE_HARDWARE_SUCCESS:
        case hardwareActions.UPDATE_HARDWARE_FAILURE:
        case hardwareActions.DELETE_HARDWARE:
        case hardwareActions.DELETE_HARDWARE_SUCCESS:
        case hardwareActions.DELETE_HARDWARE_FAILURE:
        case hardwareActions.COUNT_HARDWARES:
        case hardwareActions.COUNT_HARDWARES_SUCCESS:
        case hardwareActions.COUNT_HARDWARES_FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export default hardwareReducer;