import { get } from 'lodash';
import { createAction } from '../../utils/actionHelper';

export const initialState = {
    devices: {
        data: null,
        isFetching: false,
        filter: null
    },
    device: {
        data: null,
        isFetching: false,
        done: false,
        filter: null
    },
    currentDevice: {
        data: null,
        isFetching: false,
        filter: null
    },
    countDevices: {
        count: null,
        isFetching: false,
        filter: null
    }
};

// fetch devices
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const fetchDevices = createAction(FETCH_DEVICES, (filter) => ({
    devices: {
        isFetching: true,
        data: [],
        filter
    }
}));
export const FETCH_DEVICES_REFRESH = 'FETCH_DEVICES_REFRESH';
export const fetchDevicesRefresh = createAction(FETCH_DEVICES_REFRESH, (filter) => ({
    devices: {
        isFetching: false,
        filter
    }
}));
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const fetchDevicesSuccess = createAction(FETCH_DEVICES_SUCCESS, (filter, data) => ({
    devices: {
        isFetching: false,
        data,
        filter
    }
}));
export const FETCH_DEVICES_FAILURE = 'FETCH_DEVICES_FAILURE';
export const fetchDevicesFailure = createAction(FETCH_DEVICES_FAILURE, (filter, err) => ({
    devices: {
        err,
        isFetching: false,
        filter
    }
}));
// fetch total devices
export const COUNT_DEVICES = 'COUNT_DEVICES';
export const countDevices = createAction(COUNT_DEVICES, (filter) => ({
    countDevices: {
        isFetching: true,
        count: {},
        filter
    }
}));
export const COUNT_DEVICES_SUCCESS = 'COUNT_DEVICES_SUCCESS';
export const countDevicesSuccess = createAction(COUNT_DEVICES_SUCCESS, (count, filter) => ({
    countDevices: {
        isFetching: false,
        count,
        filter
    }
}));
export const COUNT_DEVICES_FAILURE = 'COUNT_DEVICES_FAILURE';
export const countDevicesFailure = createAction(COUNT_DEVICES_FAILURE, (filter, err) => ({
    countDevices: {
        filter,
        err,
        isFetching: false
    }
}));
// fetch device by id
export const FETCH_CURRENT_DEVICE = 'FETCH_CURRENT_DEVICE';
export const fetchCurrentDevice = createAction(FETCH_CURRENT_DEVICE, (id, filter) => ({
    currentDevice: {
        id,
        isFetching: true,
        data: [],
        filter
    }
}));
export const FETCH_CURRENT_DEVICE_SUCCESS = 'FETCH_CURRENT_DEVICE_SUCCESS';
export const fetchCurrentDeviceSuccess = createAction(FETCH_CURRENT_DEVICE_SUCCESS, (id, data, filter) => ({
    currentDevice: {
        id,
        isFetching: false,
        data,
        filter
    }
}));
export const FETCH_CURRENT_DEVICE_FAILURE = 'FETCH_CURRENT_DEVICE_FAILURE';
export const fetchCurrentDeviceFailure = createAction(FETCH_CURRENT_DEVICE_FAILURE, (id, err, filter) => ({
    currentDevice: {
        id,
        err,
        isFetching: false,
        filter
    }
}));

// create device
export const CREATE_DEVICE = 'CREATE_DEVICE';
export const createDevice = createAction(CREATE_DEVICE, (data, filter) => ({
    device: {
        isFetching: true,
        data,
        done: false,
        filter
    }
}));
export const CREATE_DEVICE_SUCCESS = 'CREATE_DEVICE_SUCCESS';
export const createDeviceSuccess = createAction(CREATE_DEVICE_SUCCESS, (data, filter) => ({
    device: {
        isFetching: false,
        data,
        done: true,
        filter
    }
}));
export const CREATE_DEVICE_FAILURE = 'CREATE_DEVICE_FAILURE';
export const createDeviceFailure = createAction(CREATE_DEVICE_FAILURE, (err, filter) => ({
    device: {
        err,
        isFetching: false,
        filter
    }
}));

// update device
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const updateDevice = createAction(UPDATE_DEVICE, (id, data) => ({
    device: {
        isFetching: true,
        id,
        data
    }
}));
export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
export const updateDeviceSuccess = createAction(UPDATE_DEVICE_SUCCESS, (data) => ({
    device: {
        isFetching: false,
        data
    }
}));
export const UPDATE_DEVICE_FAILURE = 'UPDATE_DEVICE_FAILURE';
export const updateDeviceFailure = createAction(UPDATE_DEVICE_FAILURE, (err) => ({
    device: {
        err,
        isFetching: false
    }
}));

// update device values for schedule update
export const UPDATE_DEVICE_VALUES = 'UPDATE_DEVICE_VALUES';
export const updateDeviceValues = createAction(UPDATE_DEVICE_VALUES, (id, data, filter) => ({
    device: {
        isFetching: true,
        id,
        data,
        filter
    }
}));
export const UPDATE_DEVICE_VALUES_SUCCESS = 'UPDATE_DEVICE_VALUES_SUCCESS';
export const updateDeviceValuesSuccess = createAction(UPDATE_DEVICE_VALUES_SUCCESS, (data, filter) => ({
    device: {
        isFetching: false,
        data,
        filter
    }
}));
export const UPDATE_DEVICE_VALUES_FAILURE = 'UPDATE_DEVICE_VALUES_FAILURE';
export const updateDeviceValuesFailure = createAction(UPDATE_DEVICE_VALUES_FAILURE, (err, filter) => ({
    device: {
        err,
        isFetching: false,
        filter
    }
}));

// update device deployment
export const UPDATE_DEVICE_DEPLOYMENT = 'UPDATE_DEVICE_DEPLOYMENT';
export const updateDeviceDeployment = createAction(UPDATE_DEVICE_DEPLOYMENT, (id, data, filter) => ({
    device: {
        isFetching: true,
        id,
        data,
        filter
    }
}));
export const UPDATE_DEVICE_DEPLOYMENT_SUCCESS = 'UPDATE_DEVICE_DEPLOYMENT_SUCCESS';
export const updateDeviceDeploymentSuccess = createAction(UPDATE_DEVICE_DEPLOYMENT_SUCCESS, (data, filter) => ({
    device: {
        isFetching: false,
        data,
        filter
    }
}));
export const UPDATE_DEVICE_DEPLOYMENT_FAILURE = 'UPDATE_DEVICE_DEPLOYMENT_FAILURE';
export const updateDeviceDeploymentFailure = createAction(UPDATE_DEVICE_DEPLOYMENT_FAILURE, (err, filter) => ({
    device: {
        err,
        isFetching: false,
        filter
    }
}));

// delete device
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const deleteDevice = createAction(DELETE_DEVICE, (id, filter) => ({
    device: {
        id,
        isFetching: true,
        filter
    }
}));
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const deleteDeviceSuccess = createAction(DELETE_DEVICE_SUCCESS, (data, filter) => ({
    device: {
        isFetching: false,
        data,
        filter
    }
}));
export const DELETE_DEVICE_FAILURE = 'DELETE_DEVICE_FAILURE';
export const deleteDeviceFailure = createAction(DELETE_DEVICE_FAILURE, (err, filter) => ({
    device: {
        err,
        isFetching: false,
        filter
    }
}));

// trigger FOTA for device
export const TRIGGER_FOTA = 'TRIGGER_FOTA';
export const triggerFota = createAction(TRIGGER_FOTA, (id, filter) => ({
    device: {
        id,
        isFetching: true,
        filter
    }
}));
export const TRIGGER_FOTA_SUCCESS = 'TRIGGER_FOTA_SUCCESS';
export const triggerFotaSuccess = createAction(TRIGGER_FOTA_SUCCESS, (data, filter) => ({
    device: {
        isFetching: false,
        data,
        filter
    }
}));
export const TRIGGER_FOTA_FAILURE = 'TRIGGER_FOTA_FAILURE';
export const triggerFotaFailure = createAction(TRIGGER_FOTA_FAILURE, (err, filter) => ({
    device: {
        err,
        isFetching: false,
        filter
    }
}));

// archive device
export const ARCHIVE_DEVICE = 'ARCHIVE_DEVICE';
export const archiveDevice = createAction(ARCHIVE_DEVICE, (id, data, filter) => ({
    device: {
        isFetching: true,
        id,
        data,
        filter
    }
}));
export const ARCHIVE_DEVICE_SUCCESS = 'ARCHIVE_DEVICE_SUCCESS';
export const archiveDeviceSuccess = createAction(ARCHIVE_DEVICE_SUCCESS, (data, filter) => ({
    device: {
        isFetching: false,
        data,
        filter
    }
}));
export const ARCHIVE_DEVICE_FAILURE = 'ARCHIVE_DEVICE_FAILURE';
export const archiveDeviceFailure = createAction(ARCHIVE_DEVICE_FAILURE, (err, filter) => ({
    device: {
        err,
        isFetching: false,
        filter
    }
}));

export const getDevicesData = state => get(state, ['devices', 'devices', 'data'], []);
export const getCountDevices = state => get(state, ['devices', 'countDevices', 'count'], {});
export const getDevicesIsFetching = state => get(state, ['devices', 'devices', 'isFetching'], false);
export const getUpdateDeviceIsFetching = state => get(state, ['devices', 'device', 'isFetching'], false);
export const getUpdateDeviceErrorMessage = state => get(state, ['devices', 'device', 'err', 'message'], '');
export const getUpdateDeviceValuesIsFetching = state => get(state, ['devices', 'device', 'isFetching'], false);
export const getUpdateDeviceValuesErrorMessage = state => get(state, ['devices', 'device', 'err', 'message'], '');
export const getUpdateDeviceDeploymentIsFetching = state => get(state, ['devices', 'device', 'isFetching'], false);
export const getUpdateDeviceDeploymentErrorMessage = state => get(state, ['devices', 'device', 'err', 'message'], '');
export const getCreateDeviceDone = state => get(state, ['devices', 'device', 'done'], false);
export const getCreateDeviceDoneData = state => get(state, ['devices', 'device', 'data'], {});
export const getCreateDeviceErrorMessage = state => get(state, ['devices', 'device', 'err', 'message'], '');
export const getCurrentDeviceData = state => get(state, ['devices', 'currentDevice', 'data'], {});
export const getArchiveDeviceIsFetching = state => get(state, ['devices', 'device', 'isFetching'], false);
export const getArchiveDeviceErrorMessage = state => get(state, ['devices', 'device', 'err', 'message'], '');