import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import * as actions from './_serverActions';
import serverAPI from '../../api/server';
import { history } from '../../store';

const serverMiddleware = store => next => (action) => {
    next(action);
    switch (action.type) {
        case actions.FETCH_SERVERS_REFRESH:
        case actions.FETCH_SERVERS: {
            serverAPI.getServers()
                .then((res) => {
                    store.dispatch(actions.fetchServersSuccess(res));
                })
                .catch(err => store.dispatch(actions.fetchServersFailure(err)));
            break;
        }
        case actions.FETCH_CURRENT_SERVER: {
            const id = get(action, ['payload', 'currentServer', 'id'], '');
            serverAPI.getServer(id)
                .then((res) => {
                    store.dispatch(actions.fetchCurrentServerSuccess(id, res));
                })
                .catch(err => store.dispatch(actions.fetchCurrentServerFailure(id, err)));
            break;
        }
        case actions.ADD_SERVER: {
            const data = get(action, ['payload', 'server', 'data'], {});
            serverAPI.addServer(data)
                .then((res) => {
                    store.dispatch(actions.addServerSuccess(res));
                    const id = actions.addServerSuccess(res).payload.server.data.id;
                    history.push(`/servers/${id}`)
                })
                .catch(err => store.dispatch(actions.addServerFailure(err)));
            break;
        }
        case actions.ADD_SERVER_SUCCESS: {
            store.dispatch(actions.fetchServersRefresh());
            toastr.success('Create succeeded', 'Server has been created.');
            break;
        }
        case actions.UPDATE_SERVER: {
            const id = get(action, ['payload', 'server', 'id'], '');
            const data = get(action, ['payload', 'server', 'data'], {});
            serverAPI.updateServer(id, data)
                .then((res) => {
                    store.dispatch(actions.updateServerSuccess(res));
                    history.push(`/servers/${id}`);
                })
                .catch(err => store.dispatch(actions.updateServerFailure(err)));
            break;
        }
        case actions.UPDATE_SERVER_SUCCESS: {
            store.dispatch(actions.fetchServersRefresh());
            toastr.success('Update succeeded', 'Server has been updated.');
            break;
        }
        case actions.UPDATE_FOTA_SETTINGS: {
            const id = get(action, ['payload', 'server', 'id'], '');
            const data = get(action, ['payload', 'server', 'data'], {});
            serverAPI.updateFotaSettings(id, data)
                .then((res) => {
                    store.dispatch(actions.updateFotaSettingsSuccess(res));
                })
                .catch(err => store.dispatch(actions.updateFotaSettingsFailure(err)));
            break;
        }
        case actions.UPDATE_FOTA_SETTINGS_SUCCESS: {
            store.dispatch(actions.fetchServersRefresh());
            history.push('/servers')
            toastr.success('Update succeeded', 'FOTA Settings has been updated.');
            break;
        }
        case actions.DELETE_SERVER: {
            const id = get(action, ['payload', 'server', 'id'], {});
            serverAPI.deteteServer(id)
                .then((res) => {
                    store.dispatch(actions.deleteServerSuccess(res));
                })
                .catch(err => store.dispatch(actions.deleteServerFailure(err)));
            break;
        }
        case actions.DELETE_SERVER_SUCCESS: {
            store.dispatch(actions.fetchServersRefresh());
            toastr.success('Delete succeeded', 'Server has been deleted.');
            break;
        }
        case actions.DELETE_SERVER_FAILURE: {
            toastr.error('Delete failed', 'Cannot delete this server.');
            break;
        }
        default:
            break;
    }
};
export default serverMiddleware;