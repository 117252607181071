import { createAction } from '../../utils/actionHelper';
import { get } from 'lodash';

export const initialState = {
    redirectTo: '',
    user: {
        data: {},
        isFetching: false,
        err: null,
        isLogin: false
    },
    userLogIn: {
        data: {},
        isFetching: false,
        err: null
    },
    userSignUp: {
        data: {},
        isFetching: false,
        err: null
    }
};
// ====== LOGIN ACTION ======
export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';
export const submitLogin = createAction(SUBMIT_LOGIN,
    (email, password, redirectTo) => ({
        redirectTo,
        userLogIn: {
            data: {
                email,
                password
            },
            isFetching: true
        }
    })
);
export const SUBMIT_LOGIN_SUCCESS = 'SUBMIT_LOGIN_SUCCESS';
export const submitLoginSuccess = createAction(SUBMIT_LOGIN_SUCCESS,
    (user, redirectTo) => ({
        redirectTo,
        user: {
            data: user,
            isLogin: true
        },
        userLogIn: {
            isFetching: false
        }
    })
);
export const SUBMIT_LOGIN_FAILURE = 'SUBMIT_LOGIN_FAILURE';
export const submitLoginFailure = createAction(SUBMIT_LOGIN_FAILURE,
    err => ({
        userLogIn: {
            err,
            isFetching: false
        }
    })
);
export const GET_IS_SYSADMIN_SUCCESS = 'GET_IS_SYSADMIN_SUCCESS';
export const getIsSysadminSuccess = createAction(GET_IS_SYSADMIN_SUCCESS,
    data => ({
        user: {
            data,
            isLogin: true,
            isFetching: false
        }
    })
);
// ====== USER LOGIN ======
export const MUST_LOGIN = 'MUST_LOGIN';
export const mustLogin = createAction(MUST_LOGIN,
    (redirectTo, message) => ({
        redirectTo,
        message,
        user: {
            isFetching: false,
            isLogin: false
        }
    })
);
export const USER_LOGOUT = 'USER_LOGOUT';
export const logout = createAction(USER_LOGOUT,
    token => ({
        user: {
            token,
            isFetching: false
        }
    })
);
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const logoutSuccess = createAction(USER_LOGOUT_SUCCESS,
    data => ({
        user: {
            data,
            isFetching: false
        }
    })
);
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';
export const logoutFailure = createAction(USER_LOGOUT_FAILURE,
    data => ({
        user: {
            data,
            isFetching: false
        }
    })
);


// ====== SIGNUP ACTION ======
export const SUBMIT_SIGNUP = 'SUBMIT_SIGNUP';
export const submitSignUp = createAction(SUBMIT_SIGNUP,
    (email, password) => ({
        userSignUp: {
            data: {
                email,
                password
            },
            isFetching: true
        }
    })
);
export const SUBMIT_SIGNUP_SUCCESS = 'SUBMIT_SIGNUP_SUCCESS';
export const submitSignUpSuccess = createAction(SUBMIT_SIGNUP_SUCCESS,
    data => ({
        userSignUp: {
            data,
            isSignUp: true,
            isFetching: false
        }
    })
);
export const SUBMIT_SIGNUP_FAILURE = 'SUBMIT_SIGNUP_FAILURE';
export const submitSignUpFailure = createAction(SUBMIT_SIGNUP_FAILURE,
    err => ({
        userSignUp: {
            err,
            isFetching: false
        }
    })
);

export const getUserData = state => get(state, ['auth', 'user', 'data'], {});
export const getIsLogin = state => get(state, ['auth', 'user', 'isLogin'], false);
export const getLoginIsFetching = state => get(state, ['auth', 'userLogIn', 'isFetching'], false);
export const getLoginErrorMsg = state => get(state, ['auth', 'userLogIn', 'err', 'message'], '');
export const getSignUpErrorMsg = state => get(state, ['auth', 'userSignUp', 'err', 'message'], '');
export const getSignupIsFetching = state => get(state, ['auth', 'userSignUp', 'isFetching'], false);
export const getUserId = state => get(state, ['auth', 'user', 'data', 'id'], '');