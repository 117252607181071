import API, { APIError } from './api';

const Auth = {
    setAuthorization(accessToken) {
        API.axios().defaults.headers.common.Authorization = accessToken;
    },
    removeAuthorization() {
        delete API.axios().defaults.headers.common.Authorization;
    },
    resendConfirm({ email }) {
        return new Promise((resolve, reject) => {
            API.axios().post('/users/resend-confirm-email', {
                email
            })
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    forgot({ email }) {
        return new Promise((resolve, reject) => {
            API.axios().post('/users/reset', {
                email
            })
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    login({ email, password }) {
        return new Promise((resolve, reject) => {
            API.axios().post('/users/login', {
                email,
                password
            })
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    register({ email, password }) {
        return new Promise((resolve, reject) => {
            API.axios().post('/users', {
                email,
                password
            })
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    logout() {
        return new Promise((resolve, reject) => {
            API.axios().post('/users/logout')
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        });
    }
};

export default Auth;