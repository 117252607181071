import * as serverActions from './_serverActions';

const serverReducer = (state = serverActions.initialState, action) => {
    switch (action.type) {
        case serverActions.FETCH_SERVERS:
        case serverActions.FETCH_SERVERS_SUCCESS:
        case serverActions.FETCH_SERVERS_FAILURE:
        case serverActions.FETCH_CURRENT_SERVER:
        case serverActions.FETCH_CURRENT_SERVER_SUCCESS:
        case serverActions.FETCH_CURRENT_SERVER_FAILURE:
        case serverActions.ADD_SERVER:
        case serverActions.ADD_SERVER_SUCCESS:
        case serverActions.ADD_SERVER_FAILURE:
        case serverActions.UPDATE_SERVER:
        case serverActions.UPDATE_SERVER_SUCCESS:
        case serverActions.UPDATE_SERVER_FAILURE:
        case serverActions.UPDATE_FOTA_SETTINGS:
        case serverActions.UPDATE_FOTA_SETTINGS_SUCCESS:
        case serverActions.UPDATE_FOTA_SETTINGS_FAILURE:
        case serverActions.DELETE_SERVER:
        case serverActions.DELETE_SERVER_SUCCESS:
        case serverActions.DELETE_SERVER_FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export default serverReducer;