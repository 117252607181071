import { get } from 'lodash';
import { createAction } from '../../utils/actionHelper';

export const initialState = {
    users: {
        data: null,
        isFetching: false
    },
    user: {
        data: null,
        isFetching: false,
        done: false
    },
    currentUser: {
        data: null,
        isFetching: false
    },
    userDetail: {
        data: null,
        isFetching: false
    }
};

// fetch users
export const FETCH_USERS = 'FETCH_USERS';
export const fetchUsers = createAction(FETCH_USERS, () => ({
    users: {
        isFetching: true,
        data: []
    }
}));
export const FETCH_USERS_REFRESH = 'FETCH_USERS_REFRESH';
export const fetchUsersRefresh = createAction(FETCH_USERS_REFRESH, () => ({
    users: {
        isFetching: false
    }
}));
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const fetchUsersSuccess = createAction(FETCH_USERS_SUCCESS, (data) => ({
    users: {
        isFetching: false,
        data
    }
}));
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const fetchUsersFailure = createAction(FETCH_USERS_FAILURE, (err) => ({
    users: {
        err,
        isFetching: false
    }
}));
// fetch user by id
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const fetchCurrentUser = createAction(FETCH_CURRENT_USER, (id) => ({
    currentUser: {
        id,
        isFetching: true,
        data: []
    }
}));
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const fetchCurrentUserSuccess = createAction(FETCH_CURRENT_USER_SUCCESS, (id, data) => ({
    currentUser: {
        id,
        isFetching: false,
        data
    }
}));
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';
export const fetchCurrentUserFailure = createAction(FETCH_CURRENT_USER_FAILURE, (id, err) => ({
    currentUser: {
        id,
        err,
        isFetching: false
    }
}));

// fetch user detail
export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL';
export const fetchUserDetail = createAction(FETCH_USER_DETAIL, (id) => ({
    userDetail: {
        id,
        isFetching: true,
        data: []
    }
}));
export const FETCH_USER_DETAIL_SUCCESS = 'FETCH_USER_DETAIL_SUCCESS';
export const fetchUserDetailSuccess = createAction(FETCH_USER_DETAIL_SUCCESS, (id, data) => ({
    userDetail: {
        id,
        isFetching: false,
        data
    }
}));
export const FETCH_USER_DETAIL_FAILURE = 'FETCH_USER_DETAIL_FAILURE';
export const fetchUserDetailFailure = createAction(FETCH_USER_DETAIL_FAILURE, (id, err) => ({
    userDetail: {
        id,
        err,
        isFetching: false
    }
}));

// create user
export const CREATE_USER = 'CREATE_USER';
export const createUser = createAction(CREATE_USER, (data) => ({
    user: {
        isFetching: true,
        data,
        done: false
    }
}));
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const createUserSuccess = createAction(CREATE_USER_SUCCESS, (data) => ({
    user: {
        isFetching: false,
        data,
        done: true
    }
}));
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const createUserFailure = createAction(CREATE_USER_FAILURE, (err) => ({
    user: {
        err,
        isFetching: false
    }
}));

// set user password
export const SET_USER_PASSWORD = 'SET_USER_PASSWORD';
export const setUserPassword = createAction(SET_USER_PASSWORD, (id, data) => ({
    user: {
        isFetching: true,
        id,
        data
    }
}));
export const SET_USER_PASSWORD_SUCCESS = 'SET_USER_PASSWORD_SUCCESS';
export const setUserPasswordSuccess = createAction(SET_USER_PASSWORD_SUCCESS, (data) => ({
    user: {
        isFetching: false,
        data
    }
}));
export const SET_USER_PASSWORD_FAILURE = 'SET_USER_PASSWORD_FAILURE';
export const setUserPasswordFailure = createAction(SET_USER_PASSWORD_FAILURE, (err) => ({
    user: {
        err,
        isFetching: false
    }
}));

// change user password by his/her self
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const changePassword = createAction(CHANGE_PASSWORD, (id, data) => ({
    user: {
        isFetching: true,
        id,
        data
    }
}));
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS, (data) => ({
    user: {
        isFetching: false,
        data
    }
}));
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const changePasswordFailure = createAction(CHANGE_PASSWORD_FAILURE, (err) => ({
    user: {
        err,
        isFetching: false
    }
}));

// update user
export const UPDATE_USER = 'UPDATE_USER';
export const updateUser = createAction(UPDATE_USER, (id, data) => ({
    user: {
        isFetching: true,
        id,
        data
    }
}));
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS, (data) => ({
    user: {
        isFetching: false,
        data
    }
}));
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const updateUserFailure = createAction(UPDATE_USER_FAILURE, (err) => ({
    user: {
        err,
        isFetching: false
    }
}));

// delete user
export const DELETE_USER = 'DELETE_USER';
export const deleteUser = createAction(DELETE_USER, (id) => ({
    user: {
        id,
        isFetching: true
    }
}));
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const deleteUserSuccess = createAction(DELETE_USER_SUCCESS, (data) => ({
    user: {
        isFetching: false,
        data
    }
}));
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const deleteUserFailure = createAction(DELETE_USER_FAILURE, (err) => ({
    user: {
        err,
        isFetching: false
    }
}));

export const getUsersData = state => get(state, ['users', 'users', 'data'], []);
export const getUsersIsFetching = state => get(state, ['users', 'users', 'isFetching'], false);
export const getUpdateUserIsFetching = state => get(state, ['users', 'user', 'isFetching'], false);
export const getUpdateUserErrorMessage = state => get(state, ['users', 'user', 'err', 'message'], '');
export const getSetUserPasswordErrorMessage = state => get(state, ['users', 'user', 'err', 'message'], '');
export const getChangePasswordErrorMessage = state => get(state, ['users', 'user', 'err', 'message'], '');
export const getCreateUserDone = state => get(state, ['users', 'user', 'done'], false);
export const getCreateUserErrorMessage = state => get(state, ['users', 'user', 'err', 'message'], '');
export const getCreateUserDoneData = state => get(state, ['users', 'user', 'data'], {});
export const getCurrentUserData = state => get(state, ['users', 'currentUser', 'data'], {});
export const getUserDetailData = state => get(state, ['users', 'userDetail', 'data'], {});