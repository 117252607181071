import { toastr } from 'react-redux-toastr';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { get } from 'lodash';
import { history } from '../../store';
import authAPI from '../../api/auth';
import * as actions from './_loginActions';

const loginMiddleware = store => next => (action) => {
    next(action);
    switch (action.type) {
        case REHYDRATE: {
            const currentState = store.getState();
            const userId = get(currentState, ['auth', 'user', 'data', 'id'], false);
            const token = get(currentState, ['auth', 'user', 'data', 'access_token'], '');
            const isLogin = get(currentState, ['auth', 'user', 'isLogin'], false);
            if (userId === false || isLogin === false) {
                store.dispatch(actions.mustLogin('/login'));
            }
            authAPI.setAuthorization(token);
            break;
        }
        case actions.SUBMIT_LOGIN: {
            const email = get(action, ['payload', 'userLogIn', 'data', 'email'], '');
            const password = get(action, ['payload', 'userLogIn', 'data', 'password'], '');
            authAPI.login({ email, password })
                .then((res) => {
                    const token = get(res, 'id', '');
                    const data = {
                        ...res,
                        email,
                        access_token: token,
                        id: get(res, 'userId', '')
                    };
                    authAPI.setAuthorization(token);
                    store.dispatch(actions.submitLoginSuccess(data));
                }).catch((err) => {
                    return store.dispatch(actions.submitLoginFailure({
                        statusCode: 422,
                        name: 'Error',
                        message: 'login failed',
                        code: 'LOGIN_FAILED'
                    }));
                });
            break;
        }
        case actions.SUBMIT_LOGIN_SUCCESS:
            history.push('/dashboard');
            break;
        case actions.USER_LOGOUT: {
            const logoutToken = get(action, ['payload', 'user', 'token'], '');
            authAPI.setAuthorization(logoutToken);
            authAPI.logout()
                .then((res) => {
                    store.dispatch(actions.logoutSuccess(res));
                })
                .catch(err => store.dispatch(actions.logoutFailure(err)));
            break;
        }
        case actions.USER_LOGOUT_SUCCESS:
            store.dispatch(actions.mustLogin(window.location.pathname));
            history.push('/login');
            break;
        case actions.USER_LOGOUT_FAILURE:
            toastr.error('Logout failure');
            break;
        case actions.MUST_LOGIN:
            history.push('/login');
            break;
        case actions.SUBMIT_SIGNUP: {
            const email = get(action, ['payload', 'userSignUp', 'data', 'email'], '');
            const password = get(action, ['payload', 'userSignUp', 'data', 'password'], '');
            authAPI.register({ email, password })
                .then((res) => {
                    store.dispatch(actions.submitSignUpSuccess(res));
                })
                .catch((err) => {
                    return store.dispatch(actions.submitSignUpFailure(err));
                });
            break;
        }
        case actions.SUBMIT_SIGNUP_SUCCESS:
            history.push('/login');
            break;
        case actions.SUBMIT_SIGNUP_FAILURE:
            history.push('/register');
            break;
        default:
            break;
    }
};
export default loginMiddleware;