import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import FullScreenLoading from './components/FullScreenLoading/FullScreenLoading';
import './mixins/chartjs';
import theme from './theme';
const DashboardLayout = lazy(() => import('../src/layouts/DashboardLayout/index'));
const LoginView = lazy(() => import('../src/views/auth/LoginView'));
const NotFoundView = lazy(() => import('../src/views/errors/NotFoundView'));
const RegisterView = lazy(() => import('../src/views/auth/RegisterView'));

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Suspense fallback={<FullScreenLoading />}>
        <Switch>
          <Route exact path="/login" component={LoginView} />
          <Route exact path="/register" component={RegisterView} />
          <Route path="/" component={DashboardLayout} />
          <Route path="*" component={NotFoundView} />
        </Switch>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;