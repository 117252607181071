import * as userActions from './_userActions';

const userReducer = (state = userActions.initialState, action) => {
    switch (action.type) {
        case userActions.FETCH_USERS:
        case userActions.FETCH_USERS_SUCCESS:
        case userActions.FETCH_USERS_FAILURE:
        case userActions.FETCH_CURRENT_USER:
        case userActions.FETCH_CURRENT_USER_SUCCESS:
        case userActions.FETCH_CURRENT_USER_FAILURE:
        case userActions.CREATE_USER:
        case userActions.CREATE_USER_SUCCESS:
        case userActions.CREATE_USER_FAILURE:
        case userActions.SET_USER_PASSWORD:
        case userActions.SET_USER_PASSWORD_SUCCESS:
        case userActions.SET_USER_PASSWORD_FAILURE:
        case userActions.CHANGE_PASSWORD:
        case userActions.CHANGE_PASSWORD_SUCCESS:
        case userActions.CHANGE_PASSWORD_FAILURE:
        case userActions.UPDATE_USER:
        case userActions.UPDATE_USER_SUCCESS:
        case userActions.UPDATE_USER_FAILURE:
        case userActions.DELETE_USER:
        case userActions.DELETE_USER_SUCCESS:
        case userActions.DELETE_USER_FAILURE:
        case userActions.FETCH_USER_DETAIL:
        case userActions.FETCH_USER_DETAIL_SUCCESS:
        case userActions.FETCH_USER_DETAIL_FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export default userReducer;