import API, { APIError } from './api';
import axios from 'axios';
import config from '../config/config';

const VersionAPI = {
    createVersion(firmwareId, data) {
        const formData = new FormData();
        formData.append('version', data.version);
        formData.append('chunkSize', data.chunkSize);
        formData.append('deploymentId', data.deploymentId);
        if (data.file) {
            formData.append('file', data.file);
        }
        if (data.file1) {
            formData.append('file1', data.file1);
        }
        return new Promise((resolve, reject) => {
            const token = API.axios().defaults.headers.common.Authorization;
            axios.post(`${config.apiEndpoint}/firmwares/${firmwareId}/versions`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: token
                }
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(APIError(err))
                })
        })
    }
};

export default VersionAPI;