import { get } from 'lodash';

export const initialState = {
    toastMessage: {},
    err: {
        code: '',
        message: ''
    },
    rehydrated: false
};
export const SET_APP_ERROR = 'SET_APP_ERROR';


export const setAppError = err => ({
    type: SET_APP_ERROR,
    payload: {
        err
    }
});

export const getRehydrated = state => get(state, ['app', 'rehydrated'], false);
export const getErrorCode = state => get(state, ['app', 'err', 'code'], '');
export const getErrorMessage = state => get(state, ['app', 'err', 'message'], '');