import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import * as actions from './_hardwareActions';
import hardwareAPI from '../../api/hardware';

const hardwareMiddleware = store => next => (action) => {
    next(action);
    switch (action.type) {
        case actions.FETCH_HARDWARES_REFRESH:
        case actions.FETCH_HARDWARES: {
            hardwareAPI.getHardwares()
                .then((res) => {
                    store.dispatch(actions.fetchHardwaresSuccess(res));
                })
                .catch(err => store.dispatch(actions.fetchHardwaresFailure(err)));
            break;
        }
        case actions.FETCH_CURRENT_HARDWARE: {
            const id = get(action, ['payload', 'currentHardware', 'id'], '');
            hardwareAPI.getHardware(id)
                .then((res) => {
                    store.dispatch(actions.fetchCurrentHardwareSuccess(id, res));
                })
                .catch(err => store.dispatch(actions.fetchCurrentHardwareFailure(id, err)));
            break;
        }
        case actions.ADD_HARDWARE: {
            const data = get(action, ['payload', 'hardware', 'data'], {});
            hardwareAPI.addHardware(data)
                .then((res) => {
                    store.dispatch(actions.addHardwareSuccess(res));
                })
                .catch(err => {
                    store.dispatch(actions.addHardwareFailure(err));
                    toastr.error(err.name, err.message);
                });
            break;
        }
        case actions.ADD_HARDWARE_SUCCESS: {
            store.dispatch(actions.fetchHardwaresRefresh());
            toastr.success('Create succeeded', 'Hardware has been created.');
            break;
        }
        case actions.UPDATE_HARDWARE: {
            const id = get(action, ['payload', 'hardware', 'id'], '');
            const data = get(action, ['payload', 'hardware', 'data'], {});
            hardwareAPI.updateHardware(id, data)
                .then((res) => {
                    store.dispatch(actions.updateHardwareSuccess(res));
                })
                .catch(err => {
                    store.dispatch(actions.updateHardwareFailure(err));
                    toastr.error(err.name, err.message);
                });
            break;
        }
        case actions.UPDATE_HARDWARE_SUCCESS: {
            store.dispatch(actions.fetchHardwaresRefresh());
            toastr.success('Update succeeded', 'Hardware has been updated.');
            break;
        }
        case actions.DELETE_HARDWARE: {
            const id = get(action, ['payload', 'hardware', 'id'], {});
            hardwareAPI.deteteHardware(id)
                .then((res) => {
                    store.dispatch(actions.deleteHardwareSuccess(res));
                })
                .catch(err => {
                    store.dispatch(actions.deleteHardwareFailure(err));
                    toastr.error(err.name, err.message);
                });
            break;
        }
        case actions.DELETE_HARDWARE_SUCCESS: {
            store.dispatch(actions.fetchHardwaresRefresh());
            toastr.success('Delete succeeded', 'Hardware has been deleted.');
            break;
        }
        case actions.COUNT_HARDWARES: {
            hardwareAPI.countHardwares()
                .then((res) => {
                    store.dispatch(actions.countHardwaresSuccess(res));
                })
                .catch(err => store.dispatch(actions.countHardwaresFailure(err)));
            break;
        }
        default:
            break;
    }
};
export default hardwareMiddleware;