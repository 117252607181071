import API, { APIError } from './api';

const DeploymentAPI = {
    getDeployments(filter) {
        return new Promise((resolve, reject) => {
            API.axios().get('/deployments', filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    getDeployment(id) {
        return new Promise((resolve, reject) => {
            API.axios().get(`/deployments/${id}?filter={"include":"versions"}`)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    createDeployment(data) {
        return new Promise((resolve, reject) => {
            API.axios().post('/deployments', data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    updateDeployment(id, data) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/deployments/${id}`, data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    deteteDeployment(id) {
        return new Promise((resolve, reject) => {
            API.axios().delete(`/deployments/${id}`)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    }
};

export default DeploymentAPI;