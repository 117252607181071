import API, { APIError } from './api';

const Server = {
    addServer(data) {
        return new Promise((resolve, reject) => {
            API.axios().post('/servers', data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    getServers() {
        return new Promise((resolve, reject) => {
            API.axios().get('/servers')
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    getServer(id) {
        return new Promise((resolve, reject) => {
            API.axios().get(`/servers/${id}`)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    updateServer(id, data) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/servers/${id}`, data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    updateFotaSettings(id, data) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/servers/${id}/update-sechedule-values`, data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    deteteServer(id) {
        return new Promise((resolve, reject) => {
            API.axios().delete(`/servers/${id}`)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    }
};

export default Server;