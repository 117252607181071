import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import * as actions from './_firmwareActions';
import firmwareAPI from '../../api/firmware';
import { history } from '../../store';

const firmwareMiddleware = store => next => (action) => {
    next(action);
    switch (action.type) {
        case actions.FETCH_FIRMWARES_REFRESH:
        case actions.FETCH_FIRMWARES: {
            firmwareAPI.getFirmwares()
                .then((res) => {
                    store.dispatch(actions.fetchFirmwaresSuccess(res));
                })
                .catch(err => store.dispatch(actions.fetchFirmwaresFailure(err)));
            break;
        }
        case actions.FETCH_CURRENT_FIRMWARE: {
            const id = get(action, ['payload', 'currentFirmware', 'id'], '');
            firmwareAPI.getFirmware(id)
                .then((res) => {
                    store.dispatch(actions.fetchCurrentFirmwareSuccess(id, res));
                })
                .catch(err => store.dispatch(actions.fetchCurrentFirmwareFailure(id, err)));
            break;
        }
        case actions.CREATE_FIRMWARE: {
            const data = get(action, ['payload', 'firmware', 'data'], {});
            firmwareAPI.createFirmware(data)
                .then((res) => {
                    store.dispatch(actions.createFirmwareSuccess(res));
                    const id = actions.createFirmwareSuccess(res).payload.firmware.data.id;
                    history.push(`/firmwares/${id}`)
                })
                .catch(err => store.dispatch(actions.createFirmwareFailure(err)));
            break;
        }
        case actions.CREATE_FIRMWARE_SUCCESS: {
            store.dispatch(actions.fetchFirmwaresRefresh());
            toastr.success('Create succeeded', 'Firmware has been created.');
            break;
        }
        case actions.UPDATE_FIRMWARE: {
            const id = get(action, ['payload', 'firmware', 'id'], '');
            const data = get(action, ['payload', 'firmware', 'data'], {});
            firmwareAPI.updateFirmware(id, data)
                .then((res) => {
                    store.dispatch(actions.updateFirmwareSuccess(res));
                    history.push(`/firmwares/${id}`);
                })
                .catch(err => store.dispatch(actions.updateFirmwareFailure(err)));
            break;
        }
        case actions.UPDATE_FIRMWARE_SUCCESS: {
            store.dispatch(actions.fetchFirmwaresRefresh());
            toastr.success('Update succeeded', 'Firmware has been updated.');
            break;
        }
        case actions.DELETE_FIRMWARE: {
            const id = get(action, ['payload', 'firmware', 'id'], {});
            firmwareAPI.deteteFirmware(id)
                .then((res) => {
                    store.dispatch(actions.deleteFirmwareSuccess(res));
                })
                .catch(err => {
                    store.dispatch(actions.deleteFirmwareFailure(err));
                    toastr.error('Delete failed', err.message);
                });
            break;
        }
        case actions.DELETE_FIRMWARE_SUCCESS: {
            store.dispatch(actions.fetchFirmwaresRefresh());
            toastr.success('Delete succeeded', 'Firmware has been deleted.');
            break;
        }
        case actions.COUNT_FIRMWARES: {
            firmwareAPI.countFirmwares()
                .then((res) => {
                    store.dispatch(actions.countFirmwaresSuccess(res));
                })
                .catch(err => store.dispatch(actions.countFirmwaresFailure(err)));
            break;
        }
        default:
            break;
    }
};
export default firmwareMiddleware;