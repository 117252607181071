import API, { APIError } from './api';

const User = {
    createUser(data) {
        return new Promise((resolve, reject) => {
            API.axios().post('/accounts', data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    getUsers() {
        return new Promise((resolve, reject) => {
            API.axios().get('/users')
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    getUser(id) {
        return new Promise((resolve, reject) => {
            API.axios().get(`/users/${id}`)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    setPassword(id, data) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/users/${id}/set-password`, data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    changePassword(id, data) {
        return new Promise((resolve, reject) => {
            API.axios().post(`/users/${id}/change-password`, data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    updateUser(id, data) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/users/${id}`, data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    deteteUser(id) {
        return new Promise((resolve, reject) => {
            API.axios().delete(`/users/${id}`)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    }
};

export default User;