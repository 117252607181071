import API, { APIError } from './api';

const DeviceStatsAPI = {
    getDeviceStats(filter) {
        return new Promise((resolve, reject) => {
            API.axios().get('/deviceStats', filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    countDeviceStats(filter) {
        return new Promise((resolve, reject) => {
            API.axios().get('/deviceStats/count', filter)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    }
};

export default DeviceStatsAPI;