import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';
import rootReducer from './reducer';
import config from './config/config';
import storage from 'redux-persist/lib/storage';
import { handleRequestFailureMiddleware } from './middlewares';
import loginMiddleware from './views/auth/_loginMiddleware';
import appMiddleware from './layouts/_appMiddleware';
import deviceMiddleware from './views/device/_deviceMiddleware';
import userMiddleware from './views/user/_userMiddleware';
import serverMiddleware from './views/server/_serverMiddleware';
import firmwareMiddleware from './views/firmware/_firmwareMiddleware';
import versionMiddleware from './views/version/_versionMiddleware';
import deploymentMiddleware from './views/deployment/_deploymentMiddleware';
import hardwareMiddleware from './views/hardware/_hardwareMiddleware';
import activityMiddleware from './views/activity/_activityMiddleware';
import deviceStatsMiddleware from './views/deviceStats/_deviceStatsMiddleware';

export const history = createBrowserHistory();

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['auth', 'app']
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const middlewares = [
    loginMiddleware,
    appMiddleware,
    handleRequestFailureMiddleware,
    deviceMiddleware,
    userMiddleware,
    serverMiddleware,
    firmwareMiddleware,
    versionMiddleware,
    deploymentMiddleware,
    hardwareMiddleware,
    activityMiddleware,
    deviceStatsMiddleware
];
/* eslint-disable no-underscore-dangle */
const composeEnhancers = config.env === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export default () => {
    let store = createStore(
        persistedReducer,
        undefined, // initialState = undefined, it will be passed by default arguments of side-reducer
        composeEnhancers(applyMiddleware(...middlewares)));
    let persistor = persistStore(store);
    return { store, persistor }
}