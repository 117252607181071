import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import { mustLogin } from './views/auth/_loginActions';
import App from './App';
import store from './store';
import config from './config/config';
import API from './api/api';
import { history } from './store';
import './scss/style.scss';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

export const persistStore = store();
API.init({
  baseURL: config.apiEndpoint,
  on401: () => {
    persistStore.store.dispatch(mustLogin('/'));
  },
  on404: () => {
    console.log('API not found');
  }
});

ReactDOM.render((
    <Provider store={persistStore.store}>
      <PersistGate persistor={persistStore.persistor}>
        <Router history={history}>
          <App />
          <ReduxToastr
            timeOut={3000}
            closeOnToastrClick
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            preventDuplicates />
        </Router>
      </PersistGate>
    </Provider >
), document.getElementById('root'));