import { get } from 'lodash';
import { setAppError } from './layouts/_appActions';
import { logoutSuccess } from './views/auth/_loginActions';

export const checkTokenExpired = (store, err) => {
    const code = get(err, 'code', '');
    if (code === 'INVALID_TOKEN') {
        store.dispatch(logoutSuccess());
    }
};

export const handleRequestFailureMiddleware = store => next => (action) => {
    next(action);
    if (action.type.includes('_FAILURE')) {
        const keys = Object.keys(action.payload);
        const err = get(action.payload, [keys[0], 'err'], {});
        const code = get(err, 'code', '');
        if (code === 'ACCESS_DENIED') {
            store.dispatch(setAppError(err));
        }
        checkTokenExpired(store, err);
    }
}