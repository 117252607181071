import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import * as actions from './_userActions';
import userAPI from '../../api/user';
import { history } from '../../store';

const userMiddleware = store => next => (action) => {
    next(action);
    switch (action.type) {
        case actions.FETCH_USERS_REFRESH:
        case actions.FETCH_USERS: {
            userAPI.getUsers()
                .then((res) => {
                    store.dispatch(actions.fetchUsersSuccess(res));
                })
                .catch(err => store.dispatch(actions.fetchUsersFailure(err)));
            break;
        }
        case actions.FETCH_CURRENT_USER: {
            const id = get(action, ['payload', 'currentUser', 'id'], '');
            userAPI.getUser(id)
                .then((res) => {
                    store.dispatch(actions.fetchCurrentUserSuccess(id, res));
                })
                .catch(err => store.dispatch(actions.fetchCurrentUserFailure(id, err)));
            break;
        }
        case actions.CREATE_USER: {
            const data = get(action, ['payload', 'user', 'data'], {});
            userAPI.createUser(data)
                .then((res) => {
                    store.dispatch(actions.createUserSuccess(res));
                    const id = actions.createUserSuccess(res).payload.user.data.id;
                    history.push(`/users/${id}`)
                })
                .catch(err => store.dispatch(actions.createUserFailure(err)));
            break;
        }
        case actions.CREATE_USER_SUCCESS: {
            store.dispatch(actions.fetchUsersRefresh());
            history.push('/users')
            toastr.success('Create succeeded', 'User has been created.');
            break;
        }
        case actions.UPDATE_USER: {
            const id = get(action, ['payload', 'user', 'id'], '');
            const data = get(action, ['payload', 'user', 'data'], {});
            userAPI.updateUser(id, data)
                .then((res) => {
                    store.dispatch(actions.updateUserSuccess(res));
                    history.push(`/users/${id}`);
                })
                .catch(err => store.dispatch(actions.updateUserFailure(err)));
            break;
        }
        case actions.UPDATE_USER_SUCCESS: {
            store.dispatch(actions.fetchUsersRefresh());
            toastr.success('Update succeeded', 'User has been updated.');
            break;
        }
        case actions.SET_USER_PASSWORD: {
            const id = get(action, ['payload', 'user', 'id'], '');
            const data = get(action, ['payload', 'user', 'data'], {});
            userAPI.setPassword(id, data)
                .then((res) => {
                    store.dispatch(actions.setUserPasswordSuccess(res));
                })
                .catch(err => store.dispatch(actions.setUserPasswordFailure(err)));
            break;
        }
        case actions.SET_USER_PASSWORD_SUCCESS: {
            store.dispatch(actions.fetchUsersRefresh());
            history.push('/users');
            toastr.success('Update succeeded', 'Password has been updated.');
            break;
        }
        case actions.CHANGE_PASSWORD: {
            const id = get(action, ['payload', 'user', 'id'], '');
            const data = get(action, ['payload', 'user', 'data'], {});
            userAPI.changePassword(id, data)
                .then((res) => {
                    store.dispatch(actions.changePasswordSuccess(res));
                    history.go(0);
                })
                .catch(err => store.dispatch(actions.changePasswordFailure(err)));
            break;
        }
        case actions.CHANGE_PASSWORD_SUCCESS: {
            history.push('/login');
            toastr.success('Update succeeded', 'Password has been updated.');
            break;
        }
        case actions.DELETE_USER: {
            const id = get(action, ['payload', 'user', 'id'], {});
            userAPI.deteteUser(id)
                .then((res) => {
                    store.dispatch(actions.deleteUserSuccess(res));
                })
                .catch(err => store.dispatch(actions.deleteUserFailure(err)));
            break;
        }
        case actions.DELETE_USER_SUCCESS: {
            store.dispatch(actions.fetchUsersRefresh());
            toastr.success('Delete succeeded', 'User has been deleted.');
            break;
        }
        case actions.DELETE_USER_FAILURE: {
            toastr.error('Delete failed', 'Cannot delete this user.');
            break;
        }
        case actions.FETCH_USER_DETAIL: {
            const id = get(action, ['payload', 'userDetail', 'id'], '');
            userAPI.getUser(id)
                .then((res) => {
                    store.dispatch(actions.fetchUserDetailSuccess(id, res));
                })
                .catch(err => store.dispatch(actions.fetchUserDetailFailure(id, err)));
            break;
        }
        default:
            break;
    }
};
export default userMiddleware;