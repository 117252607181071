import * as actions from './_appActions';
import { history } from '../store';

const appMiddleware = () => next => (action) => {
    next(action);
    switch (action.type) {
        case actions.SET_APP_ERROR:
            history.push('/err');
            break;
        default:
            break;
    }
};
export default appMiddleware;