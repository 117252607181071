import API, { APIError } from './api';

const FirmwareAPI = {
    getFirmwares() {
        return new Promise((resolve, reject) => {
            API.axios().get('/firmwares')
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    getFirmware(id) {
        return new Promise((resolve, reject) => {
            API.axios().get(`/firmwares/${id}?filter={"include":"deployments"}`)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    createFirmware(data) {
        return new Promise((resolve, reject) => {
            API.axios().post('/firmwares', data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    updateFirmware(id, data) {
        return new Promise((resolve, reject) => {
            API.axios().patch(`/firmwares/${id}`, data)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    deteteFirmware(id) {
        return new Promise((resolve, reject) => {
            API.axios().delete(`/firmwares/${id}`)
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    },
    countFirmwares() {
        return new Promise((resolve, reject) => {
            API.axios().get('/firmwares/count')
                .then(res => resolve(res.data))
                .catch(err => reject(APIError(err)));
        });
    }
};

export default FirmwareAPI;