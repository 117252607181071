import * as deviceActions from './_deviceActions';

const deviceReducer = (state = deviceActions.initialState, action) => {
    switch (action.type) {
        case deviceActions.FETCH_DEVICES:
        case deviceActions.FETCH_DEVICES_SUCCESS:
        case deviceActions.FETCH_DEVICES_FAILURE:
        case deviceActions.FETCH_CURRENT_DEVICE:
        case deviceActions.FETCH_CURRENT_DEVICE_SUCCESS:
        case deviceActions.FETCH_CURRENT_DEVICE_FAILURE:
        case deviceActions.COUNT_DEVICES:
        case deviceActions.COUNT_DEVICES_SUCCESS:
        case deviceActions.COUNT_DEVICES_FAILURE:
        case deviceActions.CREATE_DEVICE:
        case deviceActions.CREATE_DEVICE_SUCCESS:
        case deviceActions.CREATE_DEVICE_FAILURE:
        case deviceActions.UPDATE_DEVICE:
        case deviceActions.UPDATE_DEVICE_SUCCESS:
        case deviceActions.UPDATE_DEVICE_FAILURE:
        case deviceActions.UPDATE_DEVICE_VALUES:
        case deviceActions.UPDATE_DEVICE_VALUES_SUCCESS:
        case deviceActions.UPDATE_DEVICE_VALUES_FAILURE:
        case deviceActions.UPDATE_DEVICE_DEPLOYMENT:
        case deviceActions.UPDATE_DEVICE_DEPLOYMENT_SUCCESS:
        case deviceActions.UPDATE_DEVICE_DEPLOYMENT_FAILURE:
        case deviceActions.DELETE_DEVICE:
        case deviceActions.DELETE_DEVICE_SUCCESS:
        case deviceActions.DELETE_DEVICE_FAILURE:
        case deviceActions.TRIGGER_FOTA:
        case deviceActions.TRIGGER_FOTA_SUCCESS:
        case deviceActions.TRIGGER_FOTA_FAILURE:
        case deviceActions.ARCHIVE_DEVICE:
        case deviceActions.ARCHIVE_DEVICE_SUCCESS:
        case deviceActions.ARCHIVE_DEVICE_FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export default deviceReducer;
