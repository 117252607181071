import { get } from 'lodash';
import * as actions from './_activityActions';
import activityAPI from '../../api/activity';

const activityMiddleware = store => next => (action) => {
    next(action);
    switch (action.type) {
        case actions.FETCH_ACTIVITIES_REFRESH:
        case actions.FETCH_ACTIVITIES: {
            const filter = get(action, ['payload', 'activities', 'filter'], {});
            activityAPI.getActivities(filter)
                .then((res) => {
                    store.dispatch(actions.fetchActivitiesSuccess(res, filter));
                })
                .catch(err => store.dispatch(actions.fetchActivitiesFailure(err, filter)));
            break;
        }
        case actions.COUNT_ACTIVITIES: {
            const filter = get(action, ['payload', 'countActivities', 'filter'], {});
            const newFilter = { params: { where: filter.params.where || filter.params.filter.where } };
            activityAPI.countActivities(newFilter)
                .then((res) => {
                    store.dispatch(actions.countActivitiesSuccess(res, newFilter));
                })
                .catch(err => store.dispatch(actions.countActivitiesFailure(err, newFilter)));
            break;
        }
        case actions.FETCH_CURRENT_ACTIVITY: {
            const id = get(action, ['payload', 'currentActivity', 'id'], '');
            const filter = get(action, ['payload', 'currentActivity', 'filter'], {});
            activityAPI.getActivity(id, filter)
                .then((res) => {
                    store.dispatch(actions.fetchCurrentActivitySuccess(id, res, filter));
                })
                .catch(err => store.dispatch(actions.fetchCurrentActivityFailure(id, err, filter)));
            break;
        }
        default:
            break;
    }
};
export default activityMiddleware;