import { get } from 'lodash';
import { createAction } from '../../utils/actionHelper';

export const initialState = {
    servers: {
        data: null,
        isFetching: false
    },
    server: {
        data: null,
        isFetching: false,
        done: false
    },
    currentServer: {
        data: null,
        isFetching: false
    }
};

// fetch servers
export const FETCH_SERVERS = 'FETCH_SERVERS';
export const fetchServers = createAction(FETCH_SERVERS, () => ({
    servers: {
        isFetching: true,
        data: []
    }
}));
export const FETCH_SERVERS_REFRESH = 'FETCH_SERVERS_REFRESH';
export const fetchServersRefresh = createAction(FETCH_SERVERS_REFRESH, () => ({
    servers: {
        isFetching: false
    }
}));
export const FETCH_SERVERS_SUCCESS = 'FETCH_SERVERS_SUCCESS';
export const fetchServersSuccess = createAction(FETCH_SERVERS_SUCCESS, (data) => ({
    servers: {
        isFetching: false,
        data
    }
}));
export const FETCH_SERVERS_FAILURE = 'FETCH_SERVERS_FAILURE';
export const fetchServersFailure = createAction(FETCH_SERVERS_FAILURE, (err) => ({
    servers: {
        err,
        isFetching: false
    }
}));

// fetch server by id
export const FETCH_CURRENT_SERVER = 'FETCH_CURRENT_SERVER';
export const fetchCurrentServer = createAction(FETCH_CURRENT_SERVER, (id) => ({
    currentServer: {
        id,
        isFetching: true,
        data: []
    }
}));
export const FETCH_CURRENT_SERVER_SUCCESS = 'FETCH_CURRENT_SERVER_SUCCESS';
export const fetchCurrentServerSuccess = createAction(FETCH_CURRENT_SERVER_SUCCESS, (id, data) => ({
    currentServer: {
        id,
        isFetching: false,
        data
    }
}));
export const FETCH_CURRENT_SERVER_FAILURE = 'FETCH_CURRENT_SERVER_FAILURE';
export const fetchCurrentServerFailure = createAction(FETCH_CURRENT_SERVER_FAILURE, (id, err) => ({
    currentServer: {
        id,
        err,
        isFetching: false
    }
}));

// add server
export const ADD_SERVER = 'ADD_SERVER';
export const addServer = createAction(ADD_SERVER, (data) => ({
    server: {
        isFetching: true,
        data,
        done: false
    }
}));
export const ADD_SERVER_SUCCESS = 'ADD_SERVER_SUCCESS';
export const addServerSuccess = createAction(ADD_SERVER_SUCCESS, (data) => ({
    server: {
        isFetching: false,
        data,
        done: true
    }
}));
export const ADD_SERVER_FAILURE = 'ADD_SERVER_FAILURE';
export const addServerFailure = createAction(ADD_SERVER_FAILURE, (err) => ({
    server: {
        err,
        isFetching: false
    }
}));

// update server
export const UPDATE_SERVER = 'UPDATE_SERVER';
export const updateServer = createAction(UPDATE_SERVER, (id, data) => ({
    server: {
        isFetching: true,
        id,
        data
    }
}));
export const UPDATE_SERVER_SUCCESS = 'UPDATE_SERVER_SUCCESS';
export const updateServerSuccess = createAction(UPDATE_SERVER_SUCCESS, (data) => ({
    server: {
        isFetching: false,
        data
    }
}));
export const UPDATE_SERVER_FAILURE = 'UPDATE_SERVER_FAILURE';
export const updateServerFailure = createAction(UPDATE_SERVER_FAILURE, (err) => ({
    server: {
        err,
        isFetching: false
    }
}));

// update FOTA settings
export const UPDATE_FOTA_SETTINGS = 'UPDATE_FOTA_SETTINGS';
export const updateFotaSettings = createAction(UPDATE_FOTA_SETTINGS, (id, data) => ({
    server: {
        isFetching: true,
        id,
        data
    }
}));
export const UPDATE_FOTA_SETTINGS_SUCCESS = 'UPDATE_FOTA_SETTINGS_SUCCESS';
export const updateFotaSettingsSuccess = createAction(UPDATE_FOTA_SETTINGS_SUCCESS, (data) => ({
    server: {
        isFetching: false,
        data
    }
}));
export const UPDATE_FOTA_SETTINGS_FAILURE = 'UPDATE_FOTA_SETTINGS_FAILURE';
export const updateFotaSettingsFailure = createAction(UPDATE_FOTA_SETTINGS_FAILURE, (err) => ({
    server: {
        err,
        isFetching: false
    }
}));

// delete server
export const DELETE_SERVER = 'DELETE_SERVER';
export const deleteServer = createAction(DELETE_SERVER, (id) => ({
    server: {
        id,
        isFetching: true
    }
}));
export const DELETE_SERVER_SUCCESS = 'DELETE_SERVER_SUCCESS';
export const deleteServerSuccess = createAction(DELETE_SERVER_SUCCESS, (data) => ({
    server: {
        isFetching: false,
        data
    }
}));
export const DELETE_SERVER_FAILURE = 'DELETE_SERVER_FAILURE';
export const deleteServerFailure = createAction(DELETE_SERVER_FAILURE, (err) => ({
    server: {
        err,
        isFetching: false
    }
}));

export const getServersData = state => get(state, ['servers', 'servers', 'data'], []);
export const getServersIsFetching = state => get(state, ['servers', 'servers', 'isFetching'], false);
export const getUpdateServerIsFetching = state => get(state, ['servers', 'server', 'isFetching'], false);
export const getUpdateServerErrorMessage = state => get(state, ['servers', 'server', 'err', 'message'], '');
export const getAddServerDone = state => get(state, ['servers', 'server', 'done'], false);
export const getAddServerErrorMessage = state => get(state, ['servers', 'server', 'err', 'message'], '');
export const getAddServerDoneData = state => get(state, ['servers', 'server', 'data'], {});
export const getCurrentServerData = state => get(state, ['servers', 'currentServer', 'data'], {});
export const getUpdateFotaSettingsIsFetching = state => get(state, ['servers', 'server', 'isFetching'], false);
export const getUpdateFotaSettingsErrorMessage = state => get(state, ['servers', 'server', 'err', 'message'], '');