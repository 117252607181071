import * as versionActions from './_versionActions';

const versionReducer = (state = versionActions.initialState, action) => {
    switch (action.type) {
        case versionActions.CREATE_VERSION:
        case versionActions.CREATE_VERSION_SUCCESS:
        case versionActions.CREATE_VERSION_FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export default versionReducer;