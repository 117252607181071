import { get } from 'lodash';
import { createAction } from '../../utils/actionHelper';

export const initialState = {
    deployments: {
        data: null,
        isFetching: false,
        filter: null
    },
    deployment: {
        data: null,
        isFetching: false,
        done: false,
        firmwareId: null,
        filter: null
    },
    currentDeployment: {
        data: null,
        isFetching: false
    }
};

// fetch deployments
export const FETCH_DEPLOYMENTS = 'FETCH_DEPLOYMENTS';
export const fetchDeployments = createAction(FETCH_DEPLOYMENTS, (filter) => ({
    deployments: {
        isFetching: true,
        data: [],
        filter
    }
}));
export const FETCH_DEPLOYMENTS_REFRESH = 'FETCH_DEPLOYMENTS_REFRESH';
export const fetchDeploymentsRefresh = createAction(FETCH_DEPLOYMENTS_REFRESH, () => ({
    deployments: {
        isFetching: false
    }
}));
export const FETCH_DEPLOYMENTS_SUCCESS = 'FETCH_DEPLOYMENTS_SUCCESS';
export const fetchDeploymentsSuccess = createAction(FETCH_DEPLOYMENTS_SUCCESS, (data, filter) => ({
    deployments: {
        isFetching: false,
        data,
        filter
    }
}));
export const FETCH_DEPLOYMENTS_FAILURE = 'FETCH_DEPLOYMENTS_FAILURE';
export const fetchDeploymentsFailure = createAction(FETCH_DEPLOYMENTS_FAILURE, (err, filter) => ({
    deployments: {
        err,
        isFetching: false,
        filter
    }
}));

// fetch deployment by id
export const FETCH_CURRENT_DEPLOYMENT = 'FETCH_CURRENT_DEPLOYMENT';
export const fetchCurrentDeployment = createAction(FETCH_CURRENT_DEPLOYMENT, (id) => ({
    currentDeployment: {
        id,
        isFetching: true,
        data: []
    }
}));
export const FETCH_CURRENT_DEPLOYMENT_SUCCESS = 'FETCH_CURRENT_DEPLOYMENT_SUCCESS';
export const fetchCurrentDeploymentSuccess = createAction(FETCH_CURRENT_DEPLOYMENT_SUCCESS, (id, data) => ({
    currentDeployment: {
        id,
        isFetching: false,
        data
    }
}));
export const FETCH_CURRENT_DEPLOYMENT_FAILURE = 'FETCH_CURRENT_DEPLOYMENT_FAILURE';
export const fetchCurrentDeploymentFailure = createAction(FETCH_CURRENT_DEPLOYMENT_FAILURE, (id, err) => ({
    currentDeployment: {
        id,
        err,
        isFetching: false
    }
}));

// create deployment
export const CREATE_DEPLOYMENT = 'CREATE_DEPLOYMENT';
export const createDeployment = createAction(CREATE_DEPLOYMENT, (data) => ({
    deployment: {
        isFetching: true,
        data,
        done: false
    }
}));
export const CREATE_DEPLOYMENT_SUCCESS = 'CREATE_DEPLOYMENT_SUCCESS';
export const createDeploymentSuccess = createAction(CREATE_DEPLOYMENT_SUCCESS, (data) => ({
    deployment: {
        isFetching: false,
        data,
        done: true
    }
}));
export const CREATE_DEPLOYMENT_FAILURE = 'CREATE_DEPLOYMENT_FAILURE';
export const createDeploymentFailure = createAction(CREATE_DEPLOYMENT_FAILURE, (err) => ({
    deployment: {
        err,
        isFetching: false
    }
}));

// update deployment
export const UPDATE_DEPLOYMENT = 'UPDATE_DEPLOYMENT';
export const updateDeployment = createAction(UPDATE_DEPLOYMENT, (id, data) => ({
    deployment: {
        isFetching: true,
        id,
        data
    }
}));
export const UPDATE_DEPLOYMENT_SUCCESS = 'UPDATE_DEPLOYMENT_SUCCESS';
export const updateDeploymentSuccess = createAction(UPDATE_DEPLOYMENT_SUCCESS, (data) => ({
    deployment: {
        isFetching: false,
        data
    }
}));
export const UPDATE_DEPLOYMENT_FAILURE = 'UPDATE_DEPLOYMENT_FAILURE';
export const updateDeploymentFailure = createAction(UPDATE_DEPLOYMENT_FAILURE, (err) => ({
    deployment: {
        err,
        isFetching: false
    }
}));

// delete deployment
export const DELETE_DEPLOYMENT = 'DELETE_DEPLOYMENT';
export const deleteDeployment = createAction(DELETE_DEPLOYMENT, (id, firmwareId) => ({
    deployment: {
        id,
        isFetching: true,
        firmwareId
    }
}));
export const DELETE_DEPLOYMENT_SUCCESS = 'DELETE_DEPLOYMENT_SUCCESS';
export const deleteDeploymentSuccess = createAction(DELETE_DEPLOYMENT_SUCCESS, (data, firmwareId) => ({
    deployment: {
        isFetching: false,
        data,
        firmwareId
    }
}));
export const DELETE_DEPLOYMENT_FAILURE = 'DELETE_DEPLOYMENT_FAILURE';
export const deleteDeploymentFailure = createAction(DELETE_DEPLOYMENT_FAILURE, (err) => ({
    deployment: {
        err,
        isFetching: false
    }
}));

export const getDeploymentsData = state => get(state, ['deployments', 'deployments', 'data'], []);
export const getDeploymentsIsFetching = state => get(state, ['deployments', 'deployments', 'isFetching'], false);
export const getUpdateDeploymentIsFetching = state => get(state, ['deployments', 'deployment', 'isFetching'], false);
export const getUpdateDeploymentErrorMessage = state => get(state, ['deployments', 'deployment', 'err', 'message'], '');
export const getCreateDeploymentDone = state => get(state, ['deployments', 'deployment', 'done'], false);
export const getCreateDeploymentErrorMessage = state => get(state, ['deployments', 'deployment', 'err', 'message'], '');
export const getCreateDeploymentDoneData = state => get(state, ['deployments', 'deployment', 'data'], {});
export const getCurrentDeploymentData = state => get(state, ['deployments', 'currentDeployment', 'data'], {});
export const getCurrentDeploymentIsFetching = state => get(state, ['deployments', 'currentDeployment', 'isFetching'], false);